import React from "react";

// css
// import "./ProductCard.css";
import "./followingCard.css";

// Images
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

function FollowingCard({ details }) {
  return (
    <>
      <Box sx={{ flexGrow: 1, p:3 }}>
        <Grid container spacing={2}>
          {details.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card sx={{ width: "100%" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="194px"
                    width="223px"
                    image={item.image}
                    alt={item.name}
                  />
                  <CardContent>
                    <Typography variant="h6" component="div" sx={{marginLeft:"-10px", marginTop:"-10px"}} >
                      {item.name}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", marginLeft:"-10px" }}>
                      {item.follower}
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        style={{
                          marginLeft: "4px",
                          marginRight: "4px",
                          height: "15px",
                          color: "black",
                        }}
                      />
                      {item.ads}
                    </Box>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled
                    sx={{marginTop:"-20px"}}
                    style={{ backgroundColor: "#F7F7F8", color: "red" }}
                  >
                    Unfollow
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default FollowingCard;
