import React from "react";
import { useSelector } from "react-redux";

// PrivateRoute
import PrivateRoute from "../privateRoute/PrivateRoute";
import PublicRoute from "../publicRoute/PublicRoute";

const AppRoute = () => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  return <>{isAuthenticated ? <PrivateRoute /> : <PublicRoute />}</>;
};

export default AppRoute;
