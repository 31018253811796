import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import Header from "../../../components/Header/Header";

// Images
import Car1 from "../../../assets/images/ProductDetails/Car1.png";
import Car2 from "../../../assets/images/ProductDetails/Car2.png";
import Car3 from "../../../assets/images/ProductDetails/Car3.png";
import location from "../../../assets/images/ProductDetails/location.svg";
import dot from "../../../assets/images/ProductDetails/dot.svg";
import drop from "../../../assets/images/ProductDetails/drop.svg";
import ProductSlide from "../../../components/ProductsSlide/ProductSlide";
import Footer from "../../../components/Footer/Footer";
import Spinner from "../../../components/Spinner/Spinner";

// Style
const dotStyle = {
  alignSelf: "center",
  width: "4px",
  height: "4px",
  borderRadius: "2px",
  backgroundColor: "#707187",
};

const state_zip_dist = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

const sponsoredText = {
  background: "#F5F9C8",
  padding: "4px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const highLightText = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "110%",
};

const highLightOptionText = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const nickNameText = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "110%",
};

const sinceText = {
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "110%",
  textAlign: "left",
};

const otherAdsText = {
  color: "#00A6E8",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const detailsText = {
  marginTop: "24px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "115%",
};

const detailsText2 = {
  marginTop: "12px",
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const detailsText3 = {
  marginTop: "8px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const dashedLine = {
  borderStyle: "dashed",
  width: "100%",
  height: "0.4px",
  border: "1px dashed #D8D8DF",
  marginTop: "12px",
  marginBottom: "12px",
};

const sectionNameText = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const descriptive = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "115%",
};

const descriptiveText = {
  marginTop: "8px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

const noteText = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

// Dummy data
const dummyHeighlight = [
  {
    id: 1,
    name: "Lorem ipsum dolor sit amet consectetur. Viverra congue faucibus quis vestibulum amet urna ",
    pic: dot,
  },
  {
    id: 2,
    name: "Lorem ipsum dolor sit amet consectetur. Viverra congue faucibus quis vestibulum amet urna ",
    pic: dot,
  },
  {
    id: 3,
    name: "Lorem ipsum dolor sit amet consectetur. Viverra congue faucibus quis vestibulum amet urna ",
    pic: dot,
  },
  {
    id: 4,
    name: "Lorem ipsum dolor sit amet consectetur. Viverra congue faucibus quis vestibulum amet urna ",
    pic: dot,
  },
  {
    id: 5,
    name: "Lorem ipsum dolor sit amet consectetur. Viverra congue faucibus quis vestibulum amet urna ",
    pic: dot,
  },
];

// Section name table
const SectionName = () => (
  <Grid container spacing={"16px"}>
    {/* first grid */}
    <Grid item xs={12} sm={6} md={6}>
      <Typography sx={sectionNameText}>Section Name</Typography>

      {/* Heading */}
      <Box sx={{ display: "flex", gap: "150px" }}>
        <Typography sx={detailsText2}>Details</Typography>
        <Typography sx={detailsText2}>Details</Typography>
      </Box>

      {/* Heading Details */}
      <Box sx={{ display: "flex", gap: "150px" }}>
        <Typography sx={detailsText3}>Details</Typography>
        <Typography sx={detailsText3}>Details</Typography>
      </Box>

      <div style={dashedLine} />

      {/* Heading */}
      <Box sx={{ display: "flex", gap: "150px" }}>
        <Typography sx={detailsText2}>Details</Typography>
        <Typography sx={detailsText2}>Details</Typography>
      </Box>

      {/* Heading Details */}
      <Box sx={{ display: "flex", gap: "150px" }}>
        <Typography sx={detailsText3}>Details</Typography>
        <Typography sx={detailsText3}>Details</Typography>
      </Box>
    </Grid>

    <Grid item xs={12} sm={6} md={6}>
      <Typography sx={sectionNameText}>Section Name</Typography>

      {/* Heading */}
      <Box sx={{ display: "flex", gap: "150px" }}>
        <Typography sx={detailsText2}>Details</Typography>
        <Typography sx={detailsText2}>Details</Typography>
      </Box>

      {/* Heading Details */}
      <Box sx={{ display: "flex", gap: "150px" }}>
        <Typography sx={detailsText3}>Details</Typography>
        <Typography sx={detailsText3}>Details</Typography>
      </Box>

      <div style={dashedLine} />

      {/* Heading */}
      <Box sx={{ display: "flex", gap: "150px" }}>
        <Typography sx={detailsText2}>Details</Typography>
        <Typography sx={detailsText2}>Details</Typography>
      </Box>

      {/* Heading Details */}
      <Box sx={{ display: "flex", gap: "150px" }}>
        <Typography sx={detailsText3}>Details</Typography>
        <Typography sx={detailsText3}>Details</Typography>
      </Box>
    </Grid>
  </Grid>
);

function ProductDetails() {
  const navigate = useNavigate();

  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const [selectedImage, setSelectedImage] = useState(Car1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  // Navigate to seller profile
  const navigateToSellerProfileHandle = () => {
    setLoading(true)
    if (!isAuthenticated) {
      alert("Please login first");
      setLoading(false);
    } else
      setTimeout(() => {
        navigate("/seller-profile");
      }, 1000);
  };

  return (
    <>
      {loading && <Spinner />}
      <Header />
      <Box paddingLeft="32px" marginTop={"24px"} marginRight={"24px"}>
        <Grid container spacing={"16px"}>
          {/* Selected images */}
          <Grid item xs={12} sm={6} md={5}>
            <img
              src={selectedImage}
              alt="Car"
              style={{ width: "100%", height: "618px" }}
            />

            {/* Select Image */}
            <Box sx={{ display: "flex", marginTop: "12px", gap: "8px" }}>
              <img
                src={Car1}
                onClick={() => setSelectedImage(Car1)}
                alt="Car"
                style={{
                  width: "144px",
                  height: "94px",
                  objectFit: "cover",
                  borderRadius: "4px",
                  border:
                    selectedImage === Car1
                      ? "4px solid  #00A6E8"
                      : "2px solid transparent",
                }}
              />
              <img
                src={Car2}
                alt="Car"
                onClick={() => setSelectedImage(Car2)}
                style={{
                  width: "144px",
                  height: "94px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  border:
                    selectedImage === Car2
                      ? "4px solid  #00A6E8"
                      : "2px solid transparent",
                }}
              />
              <img
                src={Car3}
                alt="Car"
                onClick={() => setSelectedImage(Car3)}
                style={{
                  width: "144px",
                  height: "94px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  border:
                    selectedImage === Car3
                      ? "4px solid  #00A6E8"
                      : "2px solid transparent",
                }}
              />
            </Box>
          </Grid>

          {/* Data */}
          <Grid item xs={12} sm={6} md={7}>
            {/* Fixed Price text */}
            <Typography
              sx={{
                color: "#707187",
                fontFamily: "Work Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "110%",
              }}
            >
              FixedPrice
            </Typography>

            {/* Price */}
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Typography
                sx={{
                  alignSelf: "center",
                  color: "#26252C",
                  fontFamily: "Work Sans",
                  fontSize: "22px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                €{" "}
              </Typography>
              <Typography
                sx={{
                  alignSelf: "center",
                  color: "#707187",
                  fontFamily: "Work Sans",
                  fontSize: "28px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  textDecoration: "strikethrough",
                }}
              >
                199{" "}
              </Typography>
              <Typography
                sx={{
                  alignSelf: "center",
                  color: "#26252C",
                  fontFamily: "Work Sans",
                  fontSize: "28px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                189
              </Typography>
            </Box>

            {/* Product title */}
            <Typography
              sx={{
                alignSelf: "center",
                textOverflow: "ellipsis",
                color: "#26252C",
                fontFamily: "Work Sans",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "28px",
                marginTop: "14px",
              }}
            >
              Product Title
            </Typography>

            {/* Description */}
            <Box
              sx={{
                color: "#26252C",
                // width: "50%",
              }}
            >
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  fontFamily: "Work Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "18px",
                }}
              >
                Lorem ipsum dolor sit amet consectetur. Viverra congue faucibus
                quis vestibulum amet urna tempus id aenean. Faucibus iaculis
                nibh fusce sed vitae viverra. Quisque nulla arcu pharetra
                praesent volutpat non scelerisque. Augue placerat molestie
                tristique massa faucibus laoreet.
              </Typography>
            </Box>

            {/* State pin and village or district */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "24px",
              }}
            >
              {/* STate pin dist */}
              <Box sx={{ display: "flex", gap: "8px" }}>
                {/* Image and state */}
                <Box sx={{ display: "flex", gap: "4px", marginRight: "8px" }}>
                  <img src={location} alt="location icon" />
                  <Typography sx={state_zip_dist}>State</Typography>
                </Box>

                {/* Dot */}
                <div style={dotStyle} />

                <Typography sx={state_zip_dist}>1010</Typography>

                {/* Dot */}
                <div style={dotStyle} />

                <Typography sx={state_zip_dist}>Village or district</Typography>
              </Box>

              {/* Sponsored */}
              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                }}
              >
                <Typography sx={sponsoredText}>Sponsored</Typography>
                <Typography sx={sponsoredText}>Sponsored</Typography>
              </Box>
            </Box>

            {/* Highlights */}
            <Box sx={{ marginTop: "26px" }}>
              <Typography sx={highLightText}>Highlights</Typography>
              <Box sx={{ marginTop: "16px" }}>
                {dummyHeighlight?.map((item, index) => (
                  <Box display={"flex"} gap={"8px"} marginTop={"8px"}>
                    <img src={item.pic} alt="item icon" />
                    <Typography sx={highLightOptionText} key={index}>
                      {item.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            {/* Nick name and user profile */}
            <Button
              onClick={navigateToSellerProfileHandle}
              display={"flex"}
              sx={{
                alignSelf: "center",
                borderRadius: "4px",
                border: "1px solid #D8D8DF",
                background: "#FFF",
                width: "328px",
                padding: "12px",
                marginTop: "24px",
              }}
            >
              <Avatar sx={{ width: "28px", height: "28px" }} />

              {/* Name and Image */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  marginLeft: "8px",
                }}
              >
                <Box>
                  <Typography sx={nickNameText}>Nick Name</Typography>
                  <Typography sx={sinceText}>Since 2024</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "2px" }}>
                  <Typography sx={otherAdsText}>Other Ads</Typography>
                  <img
                    src={drop}
                    alt="drop icon"
                    style={{ width: "12px", height: "12px" }}
                  />
                </Box>
              </Box>
            </Button>

            {/* Details */}
            <Typography sx={detailsText}>Details</Typography>

            {/* Section name */}
            <Box sx={{ marginTop: "16px" }}>
              <SectionName />
            </Box>

            <Box sx={{ marginTop: "16px" }}>
              <SectionName />
            </Box>
          </Grid>

          {/* Descriptive Paragraph */}
          <Box marginTop={"19px"} marginLeft={"24px"}>
            <Typography sx={descriptive}>Descriptive Paragraph</Typography>

            <Typography sx={descriptiveText}>
              Lorem ipsum dolor sit amet consectetur. Viverra congue faucibus
              quis vestibulum amet urna tempus id aenean. Faucibus iaculis nibh
              fusce sed vitae viverra. Quisque nulla arcu pharetra praesent
              volutpat non scelerisque. Augue placerat molestie tristique massa
              faucibus laoreet. Lorem ipsum dolor sit amet consectetur. Viverra
              congue faucibus quis vestibulum amet urna tempus id aenean.
              Faucibus iaculis nibh fusce sed vitae viverra. Quisque nulla arcu
              pharetra praesent volutpat non scelerisque. Augue placerat
              molestie tristique massa faucibus laoreet. Lorem ipsum dolor sit
              amet consectetur. Viverra congue faucibus quis vestibulum amet
              urna tempus id aenean. Faucibus iaculis nibh fusce sed vitae
              viverra. Quisque nulla arcu pharetra praesent volutpat non
              scelerisque. Augue placerat molestie tristique massa faucibus
              laoreet.
            </Typography>
          </Box>

          {/* Note text */}
          <Box
            marginTop={"24px"}
            marginLeft={"24px"}
            sx={{
              background: "var(--Red-50, #FEF2F2)",
              padding: "8px",
              borderRadius: "4px",
              border: "1px dashed var(--Red-700, #BB1A1A)",
            }}
          >
            <Typography sx={noteText}>
              Note: Lorem ipsum dolor sit amet consectetur. Viverra congue
              faucibus quis vestibulum amet urna tempus id aenean.
            </Typography>
          </Box>
        </Grid>

        {/* Product slide */}
        <ProductSlide title="Relative Products" />
      </Box>

      {/* Footer */}
      <Box sx={{ marginTop: "41px" }}>
        <Footer />
      </Box>
    </>
  );
}

export default ProductDetails;
