import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {
  Button,
  InputAdornment,
  InputBase,
  MenuItem,
  OutlinedInput,
  Select,
  IconButton,
  Box,
  Avatar,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemText,
  Popper,
  Fade,
  Paper,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

// Images
import Mainlogo from "../../assets/images/HeaderIcon/logo with tagline.png";
import location from "../../assets/images/HeaderIcon/location.png";
import selectedLocation from "../../assets/images/HeaderIcon/selectedLocation.png";
import SearchIcon from "@mui/icons-material/Search";
import chat from "../../assets/images/HeaderIcon/comment.png";
import account_blue from "../../assets/images/HeaderIcon/account_blue.png";
import Account from "../../assets/images/HeaderIcon/Account.png";
import Dropdown from "../../assets/images/CategoryImages/arrow-down.png";
import Spinner from "../../components/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/authSlice";

const HeaderContainer = styled(Box)(({ theme }) => ({
  paddingLeft: 32,
  paddingRight: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#fff",
  height: "72px",
  gap: 15,

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    height: "auto",
    justifyContent: "center",
  },

  [theme.breakpoints.between("sm", "md")]: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "15px",
    marginBottom: "140px",
    marginTop: "20px",
  },
}));

const LogoImage = styled("img")({
  width: 191,
  height: 124,
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  "&:active": {
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
  },
  "&:focus": {
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
  },
});

const LogoText = styled(Typography)({
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontEeight: "500",
  lineHeight: "115%",
  textAlign: "left",
  marginTop: "4px",
});

const SelectDropdown = styled(Select)({
  alignSelf: "center",
  // width: "30%",
  height: "58px",
  padding: "0 8px",
  borderRadius: "4px",
  // border: "1px solid #D8D8DF",
  background: "#F7F7F8",
});

const SearchBar = styled(InputBase)({
  width: "35%",
  height: "58px",
  padding: "0 8px",
  borderRadius: "4px",
  border: "1px solid #D8D8DF",
  background: "#F7F7F8",
});

const VRLine = styled("div")({
  width: "1px",
  height: "24px",
  background: "#D8D8DF",
});

const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const PostAddButton = styled(Button)({
  background: "#00A6E8",
  height: "48px",
  color: "#FFF",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "110%",
  borderTopLeftRadius: "50px",
  borderBottomLeftRadius: "50px",
});

const Header = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );
  const userData = useSelector((state) => state.authReducer?.data) || {};


  const firstName = userData.firstName || "";
  const lastName = userData.lastName || "";

  const [selectedValue, setSelectedValue] = useState(0);
  const [userName, setUserName] = useState(`${firstName} ${lastName}`);
  const [openProfilePopup, setOpenProfilePopup] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUserName(`${firstName} ${lastName}`);
  }, [firstName, lastName]);

  const handleOpenProfile = (event) => {
    setOpenProfilePopup(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setOpenProfilePopup(null);
  };

  const open = Boolean(openProfilePopup);

  // Navigate Home Handle
  const navigateHomeHandle = () => {
    setLoading(true);
    if (!isAuthenticated) {
      alert("Please login first");
      setLoading(false);
    } else
      setTimeout(() => {
        setLoading(false);
        navigate("/home");
      }, 1000);
  };
  // select loaction
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Navigate to new post handler
  const navigateToNewPostHandler = () => {
    setLoading(true);
    if (!isAuthenticated) {
      alert("Please login first");
      setLoading(false);
    } else
      setTimeout(() => {
        setLoading(false);
        navigate("/post");
      }, 1000);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = Boolean(anchorEl);
  const id = openpop ? "simple-popover" : undefined;

  return (
    <>
      {loading && <Spinner />}
      <HeaderContainer>
        {/* Logo */}
        <Box>
          <Button
            onClick={navigateHomeHandle}
            sx={{
              backgroundColor: "transparent !important",
              boxShadow: "none !important",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "transparent !important",
              },
              "&:active": {
                backgroundColor: "transparent !important",
                boxShadow: "none !important",
              },
              "&:focus": {
                backgroundColor: "transparent !important",
                boxShadow: "none !important",
              },
            }}
          >
            <LogoImage src={Mainlogo} alt="Main Logo" />
          </Button>
        </Box>

        {/* Location */}
        <SelectDropdown
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedValue}
          onChange={handleChange}
          input={
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  {selectedValue ? (
                    <img
                      src={selectedLocation}
                      alt="icon"
                      style={{ width: 24, height: 24 }}
                    />
                  ) : (
                    <img
                      src={location}
                      alt="icon"
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </InputAdornment>
              }
            />
          }
          sx={{
            ".MuiSelect-select": {
              color: "#707187",
            },
          }}
        >
          <MenuItem value={0}>Location</MenuItem>
          <MenuItem value={10}>Option 1</MenuItem>
          <MenuItem value={20}>Option 2</MenuItem>
          <MenuItem value={30}>Option 3</MenuItem>
        </SelectDropdown>

        {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
          Open Popover
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
        </Popover> */}

        {/* Search */}
        <SearchBar
          placeholder="Search any Product, Category etc"
          startAdornment={
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />

        {/* Text */}
        <Box>
          {!isLoggedIn ? (
            <>
              {/* Login */}
              <Box
                style={{
                  display: "flex",
                  gap: 12,
                  cursor: "pointer",
                  marginLeft: "33px",
                  marginRight: "33px",
                }}
              >
                <Avatar
                  alt="Login logo"
                  src={Account}
                  sx={{ width: 24, height: 24, alignSelf: "center" }}
                />
                <Typography
                  sx={{
                    alignSelf: "center",
                    color: "#000",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "110%",
                  }}
                >
                  Login
                </Typography>
              </Box>
            </>
          ) : (
            // Login
            <Box
              style={{
                display: "flex",
                gap: 12,
                marginLeft: "33px",
                marginRight: "9px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  gap: 6,
                  cursor: "pointer",
                }}
                onClick={handleOpenProfile}
              >
                <Avatar
                  alt="Login logo"
                  src={account_blue}
                  sx={{ width: 24, height: 24, alignSelf: "center" }}
                />
                <Typography
                  sx={{
                    alignSelf: "center",
                    color: "#000",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "110%",
                  }}
                >
                  {`${userData?.firstName || ""} ${
                    userData?.lastName || ""
                  }`.trim()}
                </Typography>
                <Avatar
                  alt="Login logo"
                  src={Dropdown}
                  sx={{ width: 24, height: 24, alignSelf: "center" }}
                />
              </Box>

              {/* Vertical line */}
              <VRLine />

              <Box
                style={{
                  display: "flex",
                  gap: 6,
                  cursor: "pointer",
                }}
              >
                <Avatar
                  alt="Login logo"
                  src={chat}
                  sx={{ width: 24, height: 24, alignSelf: "center" }}
                />
                <Typography
                  sx={{
                    alignSelf: "center",
                    color: "#000",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "110%",
                  }}
                >
                  Chat
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        {/* Right side button  */}
        <ButtonContainer>
          <PostAddButton variant="contained" onClick={navigateToNewPostHandler}>
            + Post New Ad
          </PostAddButton>
        </ButtonContainer>

        {/* opening profile popover */}
        <Popover
          open={open}
          anchorEl={openProfilePopup}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List>
            {/* My account */}
            <ListItem
              component={Link}
              to="/account"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="My Account" />
            </ListItem>

            {/* Profile */}
            <ListItem
              component={Link}
              to="/profile"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Profile" />
            </ListItem>

            {/* My ads */}
            <ListItem
              component={Link}
              to="/my-ads"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="My Ads" />
            </ListItem>

            {/* Following */}
            <ListItem
              component={Link}
              to="/following"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Following" />
            </ListItem>

            {/* Favourite */}
            <ListItem
              component={Link}
              to="/favourite"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Favourite" />
            </ListItem>

            {/* Suggestions & Complaints */}
            <ListItem
              component={Link}
              to="/suggestions-complaints"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Suggestions & Complaints" />
            </ListItem>

            {/* Help */}
            <ListItem component={Link} to="/help" onClick={handleCloseProfile}>
              <ListItemText primary="Help" />
            </ListItem>

            <ListItem
              component={Link}
              to="/login"
              onClick={() => dispatch(logout())}
            >
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Popover>
      </HeaderContainer>
    </>
  );
};

export default Header;
