import React, { useState, useEffect } from "react";

// css
import "./Banner.css";

// Icons
import left from "../../assets/images/CategoryImages/arrow-down.png";

const Banner = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % data.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + data.length) % data.length);
  };

  return (
    <div className="banner-slider">
      <div className="slider-container">
        {data.map((data, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img
              src={data?.image}
              alt={data?.name}
              className="slide-image"
            />
          </div>
        ))}
        <button className="prev" onClick={prevSlide}>
          <div>
            <img src={left} alt="left dropdown" />
          </div>
        </button>
        <button className="next" onClick={nextSlide}>
          <div>
            <img src={left} alt="left dropdown" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Banner;
