import React from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// Images
import back from "../../../assets/images/SellerProfile/back.svg";
import add from "../../../assets/images/SellerProfile/add.svg";

// Components
import Header from "../../../components/Header/Header";
import ProductSlide from "../../../components/ProductsSlide/ProductSlide";
import Footer from "../../../components/Footer/Footer";

// Styles
const backBtn = {
  margin: 0,
  padding: 0,
  marginTop: "18px",
  marginLeft: "32px",
  gap: "12px",
};

const backButtonText = {
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px",
  textTransform: "none",
};

const nickNameText = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "110%",
};

const followersText = {
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const followButton = {
  color: "#00A6E8",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "22px",
};

const tableHead = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

// Dummy data
const data = [
  {
    name: "Details",
    details: ["Detail1", "Detail2", "Detail3", "Detail4", "Detail5"],
  },
];

function SellerProfile() {
  const navigate = useNavigate();

  return (
    <>
      <Header />

      {/* Back button */}
      <Button sx={backBtn} onClick={() => navigate(-1)}>
        <img
          src={back}
          alt="back icon"
          style={{ width: "32px", height: "32px" }}
        />
        <Typography sx={backButtonText}>Back</Typography>
      </Button>

      {/* Icon, Name, and Follow button and Table */}
      <Box
        sx={{
          padding: "44px 38px",
          marginTop: "12px",
          marginLeft: "32px",
          marginRight: "32px",
          backgroundColor: "#fff",
          border: "1px solid  #D8D8DF",
          background: " #FFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Avatar alt="Icon" src="" sx={{ width: "32px", height: "32px" }} />
            <Box>
              <Typography sx={nickNameText}>Nick Name</Typography>
              <Typography sx={followersText}>2,383 Followers 5 Ads</Typography>
            </Box>
          </Box>
          <Button sx={followButton}>
            <img src={add} alt="add logo" />
            Follow
          </Button>
        </Box>

        {/* Table Company details */}
        <TableContainer
          sx={{
            marginTop: "24px",
          }}
        >
          <Typography sx={{ ...tableHead, marginBottom: "12px" }}>
            Company Details
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={followersText}
                  sx={{
                    color: "black",
                    borderBottom: "none",
                    padding: 0,
                  }}
                >
                  Company Name
                </TableCell>
                {data[0].details.map((detail, index) => (
                  <TableCell
                    key={index}
                    style={followersText}
                    sx={{
                      color: "black",
                      borderBottom: "none",
                      padding: 0,
                    }}
                  >
                    Details
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell
                    style={{
                      ...followersText,
                      color: "black",
                      padding: 0,
                      borderBottom: "none",
                    }}
                  >
                    {row.name}
                  </TableCell>
                  {row.details.map((detail, cellIndex) => (
                    <TableCell
                      key={cellIndex}
                      style={{
                        ...followersText,
                        color: "black",
                        padding: 0,
                        borderBottom: "none",
                      }}
                    >
                      {detail}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider sx={{ mt: "12px" }} />

        <ProductSlide title="Seller All ads" />
      </Box>

      <Box sx={{ marginLeft: "32px", marginRight: "32px" }}>
        <ProductSlide title="Relative Products" />
      </Box>
      <Footer />
    </>
  );
}

export default SellerProfile;
