import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const FAQ_LIST = `${process.env.REACT_APP_BASE_URL}/faq`;


const getToken = () => localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);


const initialState = {
  data: [],
  status: null,
  message: null,
};

const FaqListSlice = createSlice({
  name: "FaqList",
  initialState,
  reducers: {
    faqListStart: (state) => {
      state.status = "loading";
      state.message = null;
    },
    faqListSuccess: (state, action) => {
      state.data = action.payload.data;
      state.status = "succeeded";
      state.message = action.payload.message;
    },
    faqListFailure: (state, action) => {
      state.status = "failed";
      state.message = action.payload.message;
    },
  },
});



export const { faqListStart, faqListSuccess, faqListFailure } =
  FaqListSlice.actions;

export const fetchFaqList = () => async (dispatch) => {
  const token = getToken();

  dispatch(faqListStart());
  try {
    const response = await axios.get(FAQ_LIST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    await dispatch(
      faqListSuccess({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      faqListFailure({
        message: error.response?.data?.message || error.message,
      })
    );
  }
};

export default FaqListSlice.reducer;
