import React from 'react'
import Sidebar from '../../../components/Sidebar/Sidebar'
import Header from "../../../components/Header/Header";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import FollowingCard from '../../../components/FollowingCard/FollowingCard';

// images
import Car0 from "../../../assets/images/ProductImages/car0.png";
import Car1 from "../../../assets/images/ProductImages/car1.png";
import Car2 from "../../../assets/images/ProductImages/car2.png";

const Details = [
  {
    id: 1,
    image: Car0,
    name: "Nick Name",
    follower: "2.0000 Followers",
    ads: "8 Ads",
  },
  {
    id: 1,
    image: Car1,
    name: "Nick Name",
    follower: "2.0000 Followers",
    ads: "8 Ads",
  },
  {
    id: 1,
    image: Car2,
    name: "Nick Name",
    follower: "2.0000 Followers",
    ads: "8 Ads",
  },
  {
    id: 1,
    image: Car0,
    name: "Nick Name",
    follower: "2.0000 Followers",
    ads: "8 Ads",
  },
  {
    id: 1,
    image: Car2,
    name: "Nick Name",
    follower: "2.0000 Followers",
    ads: "8 Ads",
  },
];


const Following = () => {
  return (
    <>
      <Header isLoggedIn={true} />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" marginTop={2}>
            <Box bgcolor="white">
              <Typography
                variant="h5"
                fontFamily="Work Sans"
                fontSize="20px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="28px"
                paddingLeft={3}
                paddingTop={4}
              >
                Following (07)
              </Typography>
              <Typography
                variant="subtitle2"
                color="#707187"
                fontFamily="Work Sans"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                marginTop={2}
                marginLeft={3}
              >
                Please fill in the following details to create your account.
              </Typography>
              <FollowingCard details={Details} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Following
