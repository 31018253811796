import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

// API of prduct
const CATEGORY_LIST = `${process.env.REACT_APP_BASE_URL}/categories`;
const GET_CATEGORY_BY_ID = `${process.env.REACT_APP_BASE_URL}/categories`;

const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

const initialState = {
  mainCategorydata: [],
  subCategoryMap: {},
  status: null,
  message: null,
  subCategoryData: [],
  subSubcategoryData: [],
};

export const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    categoryListSuccess: (state, action) => {
      state.mainCategorydata = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    categoryListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    subCategoryDetailsSuccess: (state, action) => {
      state.subCategoryMap[action.payload.parentCategoryId] = action.payload.subCategories;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    subCategoryDetailsFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  categoryListSuccess,
  categoryListFailure,
  subCategoryDetailsSuccess,
  subCategoryDetailsFailure,
} = CategorySlice.actions;

export const fetchCategoryList = () => async (dispatch) => {
  try {
    const response = await axios.get(CATEGORY_LIST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      categoryListSuccess({
        data: response?.data?.data,
        status: response.data.status,
        message: response.data.message,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      categoryListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const getCategoryById =
  (parentId) =>
  async (dispatch) => {
    try {
      const response = await axios.get(`${GET_CATEGORY_BY_ID}/${parentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        subCategoryDetailsSuccess({
          parentCategoryId: parentId,  // The clicked category ID
          subCategories: response.data.data, // The fetched subcategories
          status: response.data.status,
          message: response.data.message,
        })
      );
      return response;
    } catch (error) {
      dispatch(
        subCategoryDetailsFailure({
          status: false,
          message: error.message,
        })
      );
    }
  };



export default CategorySlice.reducer;
