import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// css
import "./ProductCard.css";

// Images
import Wishlist from "../../assets/images/ProductImages/favourite-add.png";
import location from "../../assets/images/ProductImages/location.png";

// COmponents
import Spinner from '../../components/Spinner/Spinner'
import { Box } from "@mui/material";

function ProductCard({ products }) {
  console.log(products);
  
  const navigate = useNavigate();

  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const [loading, setLoading] = useState(false);

  // Navigate handler
  const navigateToProductDetailsHandler = () => {
    setLoading(true);
    if (!isAuthenticated) {
      alert("Please login first");
      setLoading(false);
    } else
      setTimeout(() => {
        setLoading(false);
        navigate("/product-details");
      }, 1000);
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="product-card-container">
        {products?.length > 0 &&
          products?.map((item, index) => (
            <Box
              className="product-card"
              onClick={navigateToProductDetailsHandler}
            >
              <img src={item.images[0]} alt={item.title} />

              {/* price and wishlist */}
              <div className="price-wishlist">
                {/* price */}
                <div className="product-price">
                  <p>€</p>
                  <p className="productPrice-1">{item.price + 100}</p>
                  <p className="productPrice-2">{item.price}</p>
                </div>

                {/* Wishlist */}
                <div className="wishlist">
                  <img src={Wishlist} alt="wishlist icon" />
                </div>
              </div>

              {/* Name */}
              <div className="product-name">
                <p>
                  {item.title}
                  {index == 0 &&
                    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dignissimos ducimus dolores molestias ipsam maiores sapiente veniam cum, nihil soluta excepturi non ipsum id nesciunt quia saepe dicta accusamus sit expedita?"}
                </p>
              </div>

              {/* state and pin */}
              <div className="state-pin">
                <img src={location} alt="location icon" />
                <p style={{ marginLeft: "1.94px" }}>State</p>
                <div className="big-dot" />
                <p style={{ marginLeft: "1.94px" }}>{item.pin}</p>
                <div className="big-dot" />
              </div>

              {/* Villae or district */}
              <div className="village-district">
                <p>Village of district</p>
              </div>

              {/* Sponsored and days*/}
              <div className="sponsored">
                  <div>
                    <p className="sponsored-text">Sponsored</p>
                  </div>
                {item.sponsored && (
                <p className="sponsored-days">{item.days}</p>
                )}
              </div>
            </Box>
          ))}
      </div>
    </>
  );
}

export default ProductCard;
