import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const FAVOURITE_LIST = `${process.env.REACT_APP_BASE_URL}/follow/list`;

const token = localStorage.getItem("loginToken");

const initialState = {
    data: [],
    status: null,
    message: null,
};

export const FavouriteListSlice = createSlice({
    name: "FavouriteList",
    initialState,
    reducers: {
        favouriteListSuccess: (state, action) => {
            state.data = action.payload.data;
            state.status = action.payload.status;
            state.message = action.payload.message;
        },
        favouriteListFailure: (state, action) => {
            state.status = action.payload.status;
            state.message = action.payload.message;
        },
    },
});

export const { favouriteListSuccess, favouriteListFailure } = FavouriteListSlice.actions;

export const fetchFavouriteList = () => async (dispatch) => {
    try {
        const response = await axios.get(FAVOURITE_LIST, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch(
            favouriteListSuccess({
                status: response.data.status,
                message: response.data.message,
                data: response.data.data,
            })
        );
        return response;
    } catch (error) {
        dispatch(
            favouriteListFailure({
                status: false,
                message: error.message,
            })
        );
    }
};

export default FavouriteListSlice.reducer;