import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Login from "../../pages/public/LoginPage/Login";
import Header from "../Header/Header";
import Register from "../../pages/public/Register/Register";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabComponent = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Header />
      <Box
        sx={{ width: "30%" }}
        width={300}
        position="relative"
        left={200}
        top={30}
      >
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Login"
              {...a11yProps(0)}
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                marginLeft: "70px",
                // color: "red",
              }}
            />
            <Tab
              label="Sign Up"
              {...a11yProps(1)}
              style={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Login />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Register />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default TabComponent;
