import React from "react";
import "./App.css";
// AppRoute from Routes
import AppRoute from "./routes/appRoute/AppRoute";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="bgColor">
      <ToastContainer />
      <AppRoute />
    </div>
  );
}

export default App;
