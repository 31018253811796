import React from "react";
import { Route, Routes } from "react-router-dom";

// Public Route
import Register from "../../pages/public/Register/Register";
import NotFound from "../../pages/NotFound/NotFound";
import ForgotPassword from "../../pages/public/ForgotPassword/ForgotPassword";
import TabComponent from "../../components/Tabs/Tab";
import VerificationPage from "../../pages/public/VerificationPage/VerificationPage";
import NewPassword from "../../pages/public/NewPassword/NewPassword";
import SuccessPage from "../../pages/public/SuccessPage/SuccessPage";
import Test from "../../components/DynamicForm/Test";

const PublicRoute = ({ component: Component }) => {
  return (
    <Routes>
      <Route path="/" element={<TabComponent />} />
      <Route path="/test" element={<Test />} />
      <Route path="/login" element={<TabComponent />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/forgot-password/verification"
        element={<VerificationPage />}
      />
      <Route
        path="/email-verification"
        element={<VerificationPage />}
      />
      <Route
        path="/forgot-password/verification/newpassword"
        element={<NewPassword />}
      />
      <Route
        path="/forgot-password/verification/newpassword/passwordchange"
        element={<SuccessPage />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default PublicRoute;
