import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setUserDetails } from "../auth/authSlice";

const GET_PROFILE_BY_ID = `${process.env.REACT_APP_BASE_URL}/profile`;
const UPDATE_PROFILE = `${process.env.REACT_APP_BASE_URL}/profile`;

const getToken = () => localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

const initialState = {
  data: null,
  status: null,
  message: null,
  faqData: [],
};


export const ProfileSlice = createSlice({
  name: "ProfileList",
  initialState,
  reducers: {
    profileDetailSuccess: (state, action) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    profileDetailFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    updateProfileSuccess: (state, action) => {
      // state.data = action.payload.data;
      state.message = action.payload.data;
    },
    updateProfileFailure: (state, action) => {
      state.data = action.payload.data;
      state.message = action.payload.data;
    },
    faqListSuccess: (state, action) => {
      state.faqData = action.payload.data;
    },
  },
});

export const {
  profileDetailSuccess,
  profileDetailFailure,
  updateProfileSuccess,
  updateProfileFailure,
  faqListSuccess,
} = ProfileSlice.actions;

export const getProfileById = () => async (dispatch) => {
  const token = getToken();
  try {
    const response = await axios.get(`${GET_PROFILE_BY_ID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const profileData = response.data.data;

    const accountType = profileData.type === 1 ? "COMMERCIAL" : "PRIVATE";

    dispatch(
      profileDetailSuccess({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );

    dispatch(
      setUserDetails({
        user: profileData.user,
        accountType: accountType,
      })
    );

    return response;
  } catch (error) {
    dispatch(
      profileDetailFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const updateProfile = (formData) => async (dispatch) => {
  const token = getToken();
  delete formData["token"];
  delete formData["profileImage"];

  try {
    const response = await axios.put(`${UPDATE_PROFILE}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(
      updateProfileSuccess({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    dispatch(getProfileById());
    return response?.data;
  } catch (error) {
    dispatch(
      updateProfileFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const getFaqList = () => async (dispatch) => {
  const token = getToken();
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/faqs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      faqListSuccess({
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {}
};

export const addSuggestion = (reqBody) => async (dispatch) => {
  const token = getToken();
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/suggestion/add`,
      reqBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response?.data;
  } catch (error) {}
};

export default ProfileSlice.reducer;
