import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { encryptTransform } from "../utils/encryption";
import AuthReducer from "../store/auth/authSlice";
import BannerReducer from "./helper/banner";
import CategoryReducer from "./helper/categorySlice";
import TemplateReducer from "./template/templateSlice";
import FavouriteReducer from "../store/favourite/favouriteSlice";
import ProfileReducer from "../store/profile/profileSlice";
import FaqReducer from "../store/faq/faqSlice";
import ProductReducer from "./product/productSlice"

const appReducer = combineReducers({
  authReducer: AuthReducer,
  favouriteReducer: FavouriteReducer,
  profileReducer: ProfileReducer,
  faqReducer: FaqReducer,

  // Private slice for private routes

  // Helper
  bannerReducer: BannerReducer,
  categoryReducer: CategoryReducer,
  templateReducer: TemplateReducer,
  productReducer: ProductReducer
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  transforms: [encryptTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
