import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getProfileById } from "../profile/profileSlice";

const initialState = {
  isAuthenticated: getProfileById() ? true : false,
  data: {},
  user: null,
  token: null,
  status: null,
  message: null,
  accountType: null,
};



export const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    data: {},
  },
  reducers: {
    login: (state, action) => {
      localStorage.setItem(
        process.env.REACT_APP_LOGIN_TOKEN,
        action?.payload?.data?.token
      );
      localStorage.setItem(
        process.env.REACT_APP_USER_DATA,
        JSON.stringify(action?.payload?.data)
      );
      state.isAuthenticated = action?.payload?.isAuthenticated;
      state.data = action?.payload?.data;
      state.user = action?.payload?.data?.user;
      state.token = action?.payload?.token;
      state.accountType =
        action?.payload?.data?.type === 1 ? "COMMERCIAL" : "PRIVATE";
    },
    setProfileName: (state, action) => {
      state.data.firstName = action.payload.firstName;
      state.data.lastName = action.payload.lastName;
    },
    setUserDetails(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.accountType = action.payload?.type === 1 ? "COMMERCIAL" : "PRIVATE";
    },
    authenticate: (state, action) => {
      state.isAuthenticated = action?.payload?.isAuthenticated;
    },
    logout: (state) => {
      localStorage.clear();
      state.isAuthenticated = false;
      state.data = {};
      state.user = null;
      state.token = null;
      state.accountType = null;
    },
  },
});
const authApi = `${process.env.REACT_APP_BASE_URL}`;
const token = localStorage.getItem("loginToken");
export const registerUser = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/register`, reqBody);
    if (response?.data?.status) {
      dispatch(
        login({
          data: response?.data?.data,
          token: response?.data?.token,
          isAuthenticated: false,
        })
      );
    }
    return response;
  } catch (error) {
    console.error("Login error: ", error);
  }
};

export const loginUser = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/signin`, reqBody);
    if (response?.data?.status) {
      dispatch(
        login({
          data: response?.data?.data,
          token: response?.data?.token,
          isAuthenticated: true,
        })
      );
    }
    return response;
  } catch (error) {
    console.error("Login error: ", error);
  }
};

export const forgotPassword = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/forget-password`, reqBody);
    return response;
  } catch (error) {}
};

export const changePassword = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/changepassword`, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {}
};

export const verifyEmailOtp =
  (reqBody, shouldRedirectHome = false) =>
  async (dispatch) => {
    try {
      const response = await axios.post(`${authApi}/verify-email`, reqBody);
      if (response?.data?.status && shouldRedirectHome) {
        dispatch(
          authenticate({
            isAuthenticated: true,
          })
        );
      }
      return response?.data;
    } catch (error) {}
  };

export const resendOtp = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/send-otp`, reqBody);
    return response;
  } catch (error) {}
};

export const resetPassword = (reqBody) => async (dispatch) => {
  try {
    const response = await axios.post(`${authApi}/reset-password`, reqBody);
    return response;
  } catch (error) {}
};

export const { login, setUserDetails, logout, authenticate, setProfileName } =
  AuthSlice.actions;

export default AuthSlice.reducer;
