import CryptoJS from 'crypto-js';
import { createTransform } from 'redux-persist';


const secretKey = process.env.REACT_APP_SECRATE_KEY;
const iv = process.env.REACT_APP_SECRATE_IV;


const encrypt = (stateToEncrypt, secretKey) => {
  if (!stateToEncrypt) return stateToEncrypt;
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(stateToEncrypt), secretKey).toString();
  return encrypted;
};

const decrypt = (encryptedState, secretKey) => {
  if (!encryptedState) return encryptedState;
  const bytes = CryptoJS.AES.decrypt(encryptedState, secretKey);
  const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decrypted;
};

const encryptTransform = createTransform(
  (stateToEncrypt, key) => encrypt(stateToEncrypt, key),
  (encryptedState, key) => decrypt(encryptedState, key)
);

const encryptPassword = {
  encrypt: (text) => {
    const ciphertext = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(secretKey), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return ciphertext.toString();
  },
};

export { encryptTransform, encryptPassword };
