import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";

const ComplexSelectPopup = ({
  open,
  onClose,
  childrenFields,
  onSelect,
  onSave,
  prefilledValues, // The prefilled values
}) => {
  const [values, setValues] = useState({});
  const [checkbox_TextinputValues, setCheckbox_TextinputValues] = useState({});

  // Set prefilled values when the component mounts or when `open` changes
  useEffect(() => {
    if (open) {
      setValues(prefilledValues || {});
      setCheckbox_TextinputValues(prefilledValues?.checkbox_Textinput || {});
    }
  }, [open, prefilledValues]);

  const reformatData = (data) => {
    return Object.entries(data).map(([key, value]) => {
      // Check if the value is empty
      if (value === "") {
        return key; // Return the full key without ":"
      } else {
        return `${key}: ${value}`; // Return key and value
      }
    });
  };
  
  

  const handleChange = (field, value, checkbox_TextinputType = null) => {
    if (field.type === "checkbox_Textinput") {
      const checkboxLabel = field.checkboxTextinputProps?.checkboxLabel;
      let savedData = { ...checkbox_TextinputValues };

      if (checkbox_TextinputType === "checkbox") {
        // Handle checkbox selection in checkbox_Textinput
        if (value) {
          if (!savedData[checkboxLabel]) {
            savedData[checkboxLabel] = "";
          }
        } else {
          delete savedData[checkboxLabel];
        }
      } else {
        // Handle text input change in checkbox_Textinput
        savedData[checkboxLabel] = value;
      }

      setCheckbox_TextinputValues({ ...savedData });
    } else {
      if (field.type === "checkbox") {
        onSelect(field.label, value); // For checkboxes, store label and checked value
      } else {
        onSelect(value); // For other fields, store name and value
      }
      // Update local state for the form input values
      setValues((prevValues) => ({
        ...prevValues,
        [field.name]: value,
      }));
    }
  };

  const handleSave = () => {
    const formattedData = reformatData(checkbox_TextinputValues);
    if (formattedData?.length > 0) {
      onSave(formattedData);
    }else{

      onSave();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Value</DialogTitle>
      <DialogContent>
        {childrenFields.map((childField) => {
          switch (childField.type) {
            case "text":
              return (
                <TextField
                  variant="standard"
                  key={childField.name}
                  label={childField.label}
                  name={childField.name}
                  value={values[childField.name] || ""} // Prefill the value
                  onChange={(e) => handleChange(childField, e.target.value)}
                  fullWidth
                  margin="normal"
                />
              );
            case "checkbox":
              return (
                <FormControlLabel
                  key={childField.name}
                  control={
                    <Checkbox
                      name={childField.name}
                      checked={values[childField.name] || false} // Prefill checkbox state
                      onChange={(e) =>
                        handleChange(childField, e.target.checked)
                      }
                    />
                  }
                  label={childField.label}
                />
              );
            case "date":
              const minDate = childField.pastDate
                ? "1900-01-01"
                : new Date().toISOString().split("T")[0];
              const maxDate = childField.futureDate
                ? "2100-12-31"
                : new Date().toISOString().split("T")[0];

              return (
                <TextField
                  variant="standard"
                  key={childField.name}
                  label={childField.label}
                  type="date"
                  name={childField.name}
                  value={values[childField.name] || ""} // Prefill the date
                  onChange={(e) => handleChange(childField, e.target.value)}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: minDate,
                    max: maxDate,
                  }}
                />
              );
            case "plaintext":
              return (
                <Box
                  onClick={() => onSelect(childField.label)}
                  padding={"0.5rem 0rem"}
                >
                  <Typography
                    sx={{ cursor: "pointer" }}
                    variant="body1"
                    key={childField.name}
                    margin="normal"
                  >
                    {childField.label} {/* Show label or selected value */}
                  </Typography>
                </Box>
              );
            case "checkbox_Textinput":
              return (
                <Box
                  padding={"0.5rem 0rem"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"0.4rem"}
                >
                  <Box width={"50%"}>
                    <FormControlLabel
                      key={childField.checkboxTextinputProps?.checkboxLabel}
                      control={
                        <Checkbox
                          name={childField.checkboxTextinputProps?.checkboxName}
                          checked={checkbox_TextinputValues.hasOwnProperty(
                            childField.checkboxTextinputProps?.checkboxLabel
                          )}
                          onChange={(e) =>
                            handleChange(
                              childField,
                              e.target.checked,
                              "checkbox"
                            )
                          }
                        />
                      }
                      label={childField.checkboxTextinputProps?.checkboxLabel}
                    />
                  </Box>
                  <Box width={"50%"}>
                    <TextField
                      variant="standard"
                      key={childField.checkboxTextinputProps?.textInputName}
                      label={
                        childField.checkboxTextinputProps?.textInputPlaceholder
                      }
                      disabled={
                        checkbox_TextinputValues.hasOwnProperty(
                          childField.checkboxTextinputProps?.checkboxLabel
                        )
                          ? false
                          : true
                      }
                      name={childField.checkboxTextinputProps?.textInputName}
                      value={
                        checkbox_TextinputValues[
                          childField.checkboxTextinputProps?.checkboxLabel
                        ] || ""
                      }
                      onChange={(e) => handleChange(childField, e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  </Box>
                </Box>
              );
            default:
              return null;
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComplexSelectPopup;
