import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { Box, Grid, Paper, Typography, styled } from "@mui/material";

// images
import Car0 from "../../../assets/images/ProductImages/car0.png";
import Car1 from "../../../assets/images/ProductImages/car1.png";
import Car2 from "../../../assets/images/ProductImages/car2.png";
import FavouriteCard from "../../../components/FavouriteCard/FavouriteCard";
import { fetchFavouriteList } from "../../../store/favourite/favouriteSlice";
import { useDispatch, useSelector } from "react-redux";
// import { Box } from "@mui/material";

const products = [
  {
    id: 1,
    image: Car0,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "7day left",
    previousPrice: "199,00",
    currentPrice: "189,00",
    views: 340,
    sponsored: true,
    status: "Active",
  },
  {
    id: 2,
    image: Car1,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "7day left",
    previousPrice: "199,00",
    currentPrice: "189,00",
    views: 340,
    sponsored: true,
    status: "Active",
  },
  {
    id: 3,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "7day left",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
    views: 340,
    status: "Active",
  },
  {
    id: 4,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "7day left",
    previousPrice: "199,00",
    currentPrice: "189,00",
    views: 340,
    sponsored: false,
    status: "Inactive",
  },
  {
    id: 5,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "7day left",
    previousPrice: "199,00",
    currentPrice: "189,00",
    sponsored: false,
    views: 340,
    status: "Inactive",
  },
  {
    id: 6,
    image: Car2,
    name: "Lorem ipsum is a dummy text",
    price: 5999,
    state: "State",
    pin: 800008,
    village: "Village of district",
    days: "7day left",
    previousPrice: "199,00",
    currentPrice: "189,00",
    views: 340,
    sponsored: false,
    status: "Inactive",
  },
];

const Favourite = () => {
  const dispatch = useDispatch();
  const FavouriteList = useSelector((state) => state?.favouriteReducer?.data);
  console.log("2",FavouriteList);


  // const [products, setProducts] = useState([]);

  // useEffect(() => {
  //   dispatch(fetchFavouriteList());
  // }, []);

  // React.useEffect(() => {
  //   setProducts(FavouriteList);
  // }, [FavouriteList]);


  return (
    <>
      <Header isLoggedIn={true} />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" marginTop={2}>
            <Box bgcolor="white" gap={12} sx={{height:"100%"}}>
              <Typography
                variant="h5"
                fontFamily="Work Sans"
                fontSize="20px"
                fontWeight="600"
                fontStyle="normal"
                lineHeight="28px"
                paddingLeft={3}
                paddingTop={4}
              >
                Favourite
              </Typography>
              <Typography
                variant="subtitle2"
                color="#707187"
                fontFamily="Work Sans"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
                marginTop={2}
                marginLeft={3}
              >
                Please fill in the following details to create your account.
              </Typography>

              <FavouriteCard products={products} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Favourite;
