import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

// API of prduct
// const API_URL =
//   "https://verkaufdev.semseosmo.com/api/v1/template/12_24_50?type=0";
const TEMPLATE_LIST = `${process.env.REACT_APP_BASE_URL}`;

const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

const initialState = {
  templateData: [],
};

export const TemplateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    templateListSuccess: (state, action) => {
      state.templateData = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    templateListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const { templateListSuccess, templateListFailure } =
  TemplateSlice.actions;

export const fetchTemplate =
  ({ template_id, templateType }) =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        `${TEMPLATE_LIST}/template/${template_id}?type=${templateType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        templateListSuccess({
          data: response?.data?.data,
          status: response.data.status,
          message: response.data.message,
        })
      );
      return response;
    } catch (error) {
      dispatch(
        templateListFailure({
          status: false,
          message: error.message,
        })
      );
    }
  };

export default TemplateSlice.reducer;
