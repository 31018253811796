import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

// API of prduct
// https://verkaufdev.semseosmo.com/api/v1/product/newlisting
const PRODUCT_API = `${process.env.REACT_APP_BASE_URL}`;

const initialState = {
  submitData: {},
  localPreviewProductData: {},
  publishData:{},
  data: [],
  status: null,
  message: null,
};

export const ProductListSlice = createSlice({
  name: "ProductList",
  initialState,
  reducers: {
    productListSuccess: (state, action) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    saveLocalPreviewProduct: (state, action) => {
      state.localPreviewProductData = action.payload.data;
    },
    saveProductPublishData: (state, action) => {
      state.publishData = action.payload.data;
    },
    productListFailure: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  productListSuccess,
  productListFailure,
  saveLocalPreviewProduct,
  saveProductPublishData
} = ProductListSlice.actions;

export const fetchProductList = () => async (dispatch) => {
  const token = localStorage.getItem("loginToken");
  try {
    const response = await axios.get(`${PRODUCT_API}/product/newlisting`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(
      productListSuccess({
        status: response.data.status,
        message: response.data.message,
        data: response.data.data,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      productListFailure({
        status: false,
        message: error.message,
      })
    );
  }
};

export const uploadProduct = (formData) => async (dispatch) => {
  const token = localStorage.getItem("loginToken");
  try {
    const response = await axios.post(`${PRODUCT_API}/product`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const localPreviewProduct = (previewData) => async (dispatch) => {
  try {
    dispatch(
      saveLocalPreviewProduct({
        data: previewData,
      })
    );
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export const Productpublish = (data) => async (dispatch) => {
  try {
    dispatch(
      saveProductPublishData({
        data: data,
      })
    );
  } catch (error) {
    // Dispatch failure action with error message
  }
};

export default ProductListSlice.reducer;
