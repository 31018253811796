import React from "react";

// css
import "./adsCard.css";

// Images
import Calendar from "../../assets/images/AdsPage/calendar.png";
import View from "../../assets/images/AdsPage/view.png";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Card, CardContent, CardMedia, Chip, Grid, IconButton, Typography, useTheme } from "@mui/material";

const CHIP_COLORS = {
  Active: "success",
  Inactive: "default",
};

function AdsCard({ products }) {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={2}>
          {products.map((item, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card
                sx={{
                  display: "flex",
                  position: "relative",
                  boxShadow: "none",
                  border: "none",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 151 }}
                  image={item.image}
                  alt={item.name}
                />
                <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <Box sx={{ position: "absolute", right: 8 }}>
                    <IconButton aria-label="more options">
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ marginTop: "-10px", fontSize: "20px" }}
                    >
                      € {item.previousPrice}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      <Chip
                        sx={{
                          marginLeft: "85px",
                          fontSize: "10px",
                          height: "20px",
                          marginTop: "-65px",
                        }}
                        color={CHIP_COLORS[item.status]}
                        label={item.status}
                      />
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                      sx={{ marginTop: "-30px", fontSize: "14px" }}
                    >
                      {item.name}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                  >
                    <img src={View} alt="view" className="icon" />
                    <Typography marginRight={2} fontSize={14}>
                      {item.views}
                    </Typography>

                    <img src={Calendar} alt="view" className="icon" />
                    <Typography fontSize={14}>{item.days}</Typography>
                  </Box>
                  <Box sx={{ position: "absolute", top: 95, right: 22 }}>
                    <Typography variant="body1" color="gray" fontSize={12}>
                      3d
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default AdsCard;
