import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Email from "../../../assets/images/LoginImages/mail.png";
import Header from "../../../components/Header/Header";
import "./forgotPassword.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { forgotPassword } from "../../../store/auth/authSlice";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backHomeBtn = () => {
    navigate("/login");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email) {
      const reqBody = {
        email: email,
      };
      dispatch(forgotPassword(reqBody)).then((res) => {
        if (res?.data?.status) {
          sessionStorage.setItem("userEmail", email);
          toast.success(res?.data?.message || "Something went wrong !");
          navigate("/forgot-password/verification");
        } else {
          toast.error(res?.data?.message || "Something went wrong !");
        }
      });
    }
  };

  return (
    <>
      <Header />
      <Button
        startIcon={<ArrowBackIcon />}
        sx={{
          color: " #707187",
          position: "relative",
          top: "50px",
          left: "220px",
        }}
        onClick={backHomeBtn}
      >
        Back To Login Page
      </Button>
      <Box
        container
        sx={{
          border: "0.5px solid #D8D8DF",
          position: "relative",
          height: "410px",
          width: "936px",
          left: "200px",
          top: "50px",
          bgcolor: "#FFF",
          boxShadow:
            "3px 10px 3px 0px rgba(0, 0, 0, 0.00), 2px 7px 3px 0px rgba(0, 0, 0, 0.01), 1px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Box sx={{ padding: "45px" }}>
          <div className="loginfom">
            <h1 className="headline">Forgot Your Password?</h1>
            <p className="subtitle">
              No worries, it happens to the best of us! Just enter your account
              linked email.
            </p>
            <form onSubmit={handleSubmit} action="" className="form">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <FormControl variant="standard">
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{
                        marginLeft: "35px",
                        color: "#26252C",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "18px",
                      }}
                    >
                      Email Id
                    </InputLabel>
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Here"
                      sx={{ width: "250px", color: "grey", marginTop: "20px" }}
                      id="input-with-icon-adornment"
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={Email} alt="email" className="icon" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                // disabled
                type="submit"
                sx={{
                  width: "300px",
                  marginTop: "60px",
                  backgroundColor: "#00A6E8",
                  color: "white",
                }}
              >
                Send OTP
              </Button>
            </form>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ForgotPassword;
