import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { addSuggestion, getFaqList } from "../../../store/profile/profileSlice";
import { toast } from "react-toastify";

const Help = () => {
  const [issue, setIssue] = useState("");
  const faqData = useSelector((state) => state?.profileReducer?.faqData);
  const dispatch = useDispatch();

  const handleAddIssue = () => {
    dispatch(addSuggestion({ suggestion: issue })).then((res) => {
      if(res?.status){
        toast.success(res?.message)
      }else{
        toast.error("Something went wront !")
      }
    });
  };
  useEffect(() => {
    dispatch(getFaqList());
  }, []);
  console.log(issue ? false : true, issue);
  
  return (
    <>
      <Header isLoggedIn={true} />
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={8.9} overflow="hidden" marginTop={2}>
            <Box bgcolor="white">
              <Box padding={3}>
                <Typography
                  variant="h5"
                  fontFamily="Work Sans"
                  fontSize="20px"
                  fontWeight="600"
                  fontStyle="normal"
                  lineHeight="28px"
                >
                  Help
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#707187"
                  fontFamily="Work Sans"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="22px"
                  marginTop={2}
                >
                  Please fill in the following details to create your account.
                </Typography>
                <Divider sx={{ marginTop: "20px", padding: 0 }} />
                <Box>
                  <div
                    style={{
                      marginTop: "23px",
                      color: "#26252C",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                  >
                    <p> Suggestion & Complaints </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{ marginLeft: "53px", marginTop: "-2px" }}
                  />
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    display="flex"
                    alignItems="center"
                    marginTop={2}
                  >
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        style={{
                          color: "#26252C",
                          fontFamily: "Work Sans",
                          fontSize: "17px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "18px",
                        }}
                      >
                        Describe Your Issue
                      </InputLabel>
                      <Input
                        onChange={(e) => setIssue(e.target.value)}
                        sx={{ width: "157vh" }}
                        placeholder="Enter Here"
                        id="input-with-icon-adornment"
                        startAdornment={
                          <InputAdornment position="start"></InputAdornment>
                        }
                      />
                      <Button
                        onClick={handleAddIssue}
                        disabled={issue ? false : true}
                        variant="contained"
                        type="submit"
                        sx={{
                          width: "300px",
                          textTransform: "none",
                          backgroundColor: "#00A6E8",
                          color: "white",
                          marginTop: "13px",
                        }}
                      >
                        Send Feedback
                      </Button>
                    </FormControl>
                  </Grid>
                  <div
                    style={{
                      color: "#26252C",
                      fontFamily: "Work Sans",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "22px",
                      marginTop: "45px",
                    }}
                  >
                    <p> FAQ </p>
                  </div>
                  <Divider
                    className="horizontalLine"
                    style={{ marginLeft: "-111px", marginTop: "-2px" }}
                  />
                  <Box sx={{ marginBottom: "80px" }}>
                    {/* {faqData?.length > 0 &&
                      faqData?.map((item) => ( */}
                    <Accordion
                      // key={item?.id + "faqList"}
                      sx={{
                        boxShadow: "none",
                        "&::before": {
                          display: "none",
                        },
                        border: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {/* {item?.question} */}
                        Lorem ipsum is a dummy text
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* {item?.answer} */}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Repellat assumenda tempore dolore ipsum accusamus
                        distinctio necessitatibus voluptatibus dolores
                        accusantium! Corporis maiores adipisci fugit, aut magnam
                        dolor aliquid quam voluptatum pariatur?
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      // key={item?.id + "faqList"}
                      sx={{
                        boxShadow: "none",
                        "&::before": {
                          display: "none",
                        },
                        border: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {/* {item?.question} */}
                        Lorem ipsum is a dummy text
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* {item?.answer} */}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Repellat assumenda tempore dolore ipsum accusamus
                        distinctio necessitatibus voluptatibus dolores
                        accusantium! Corporis maiores adipisci fugit, aut magnam
                        dolor aliquid quam voluptatum pariatur?
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      // key={item?.id + "faqList"}
                      sx={{
                        boxShadow: "none",
                        "&::before": {
                          display: "none",
                        },
                        border: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {/* {item?.question} */}
                        Lorem ipsum is a dummy text
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* {item?.answer} */}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Repellat assumenda tempore dolore ipsum accusamus
                        distinctio necessitatibus voluptatibus dolores
                        accusantium! Corporis maiores adipisci fugit, aut magnam
                        dolor aliquid quam voluptatum pariatur?
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      // key={item?.id + "faqList"}
                      sx={{
                        boxShadow: "none",
                        "&::before": {
                          display: "none",
                        },
                        border: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {/* {item?.question} */}
                        Lorem ipsum is a dummy text
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* {item?.answer} */}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Repellat assumenda tempore dolore ipsum accusamus
                        distinctio necessitatibus voluptatibus dolores
                        accusantium! Corporis maiores adipisci fugit, aut magnam
                        dolor aliquid quam voluptatum pariatur?
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      // key={item?.id + "faqList"}
                      sx={{
                        boxShadow: "none",
                        "&::before": {
                          display: "none",
                        },
                        border: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {/* {item?.question} */}
                        Lorem ipsum is a dummy text
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* {item?.answer} */}
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Repellat assumenda tempore dolore ipsum accusamus
                        distinctio necessitatibus voluptatibus dolores
                        accusantium! Corporis maiores adipisci fugit, aut magnam
                        dolor aliquid quam voluptatum pariatur?
                      </AccordionDetails>
                    </Accordion>
                    {/* ))} */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Help;
