import React from "react";
import { Button, Grid, Typography } from "@mui/material";

// Style
const categoryHeading = {
  color: "#FFF",
  fontFamily: "Work Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28px",
};

const categoryDataText = {
  padding: 0,
  textAlign: "left",
  marginTop: "12px",
  color: "#FFF",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

// Category dummy data
const categoryData = [
  { id: 1, name: "My Pets" },
  { id: 2, name: "Marketplace" },
  { id: 3, name: "Automobile" },
  { id: 4, name: "Properties" },
  { id: 5, name: "Bicycle, Spare Parts & Accessories" },
  { id: 6, name: "Electric Vehicle" },
];

// myLink dummy data
const myLink = [
  { id: 1, name: "My Account" },
  { id: 2, name: "My Ads" },
  { id: 3, name: "Following" },
  { id: 4, name: "Properties" },
  { id: 5, name: "Bicycle, Spare Parts & Accessories" },
];

// others dummy data
const others = [
  { id: 1, name: "About Us" },
  { id: 2, name: "Term and condition" },
  { id: 3, name: "Privacy & Policy" },
];

// socialMedia dummy data
const socialMedia = [
  { id: 1, name: "TikTok" },
  { id: 2, name: "Facebook" },
  { id: 3, name: "Instagram" },
  { id: 4, name: "YouTube" },
];

// contactInfo dummy data
const contactInfo = [
  { id: 1, name: "Email 1" },
  { id: 2, name: "Email 2" },
];

function Footer() {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        background: "#00a6e8",
        marginTop: "24px",
        paddingBottom: "24px",
      }}
      justifyContent={"center"}
    >
      {/* Category */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>Category</Typography>
        {categoryData.map((item, index) => (
          <Grid>
            <Button key={index} sx={categoryDataText}>
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* MyLink */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>My Link</Typography>
        {myLink.map((item, index) => (
          <Grid>
            <Button key={index} sx={categoryDataText}>
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Others */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>Others</Typography>
        {others.map((item, index) => (
          <Grid>
            <Button key={index} sx={categoryDataText}>
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Social media */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>
          Social Media Change to logo
        </Typography>
        {socialMedia.map((item, index) => (
          <Grid>
            <Button key={index} sx={categoryDataText}>
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Contact Info */}
      <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
        <Typography sx={categoryHeading}>Contact Info</Typography>
        {contactInfo.map((item, index) => (
          <Grid>
            <Button key={index} sx={categoryDataText}>
              {item.name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default Footer;
