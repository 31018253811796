import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import Password from "../../../assets/images/LoginImages/password.png";
import { encryptPassword } from "../../../utils/encryption";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../store/auth/authSlice";
import { toast } from "react-toastify";

function NewPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backHomeBtn = () => {
    navigate("/login");
  };
  const handleEncrypt = (text) => {
    const encrypted = encryptPassword.encrypt(text);
    return encrypted;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const encryptedPassword = handleEncrypt(password);
    if (password && encryptedPassword) {
      const reqBody = {
        email: sessionStorage.getItem("userEmail"),
        password: encryptedPassword,
      };
      dispatch(resetPassword(reqBody)).then((res) => {
        if (res?.data?.status) {
          toast.success(
            res?.data?.message || "Your password has been successfully reset"
          );
          navigate("/forgot-password/verification/newpassword/passwordchange");
        } else {
          toast.error(res?.data?.message || "Something went wrong !");
        }
      });
    }
  };

  return (
    <>
      <Header />
      <Button
        startIcon={<ArrowBackIcon />}
        sx={{
          color: " #707187",
          position: "relative",
          top: "50px",
          left: "220px",
        }}
        onClick={backHomeBtn}
      >
        Back To Login Page
      </Button>
      <Box
        container
        sx={{
          border: "0.5px solid #D8D8DF",
          position: "relative",
          height: "410px",
          width: "936px",
          left: "200px",
          top: "50px",
          bgcolor: "#FFF",
          boxShadow:
            "3px 10px 3px 0px rgba(0, 0, 0, 0.00), 2px 7px 3px 0px rgba(0, 0, 0, 0.01), 1px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Box sx={{ padding: "45px" }}>
          <div className="loginfom">
            <h1 className="headline">Check Your Email.</h1>
            <p className="subtitle">
              Please check akgerrani@gmail.com email and enter 6 digit code.
            </p>
            <form onSubmit={handleSubmit} action="" className="form">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <FormControl variant="standard">
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{
                        marginLeft: "35px",
                        color: "#26252C",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "18px",
                      }}
                    >
                      Password
                    </InputLabel>
                    <Input
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter Here"
                      sx={{ width: "200px", color: "grey" }}
                      id="input-with-icon-adornment"
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={Password} alt="password" className="icon" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} display="flex" alignItems="center">
                  <FormControl variant="standard" sx={{ marginLeft: "40px" }}>
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{
                        marginLeft: "35px",
                        color: "#26252C",
                        fontFamily: "Work Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "18px",
                      }}
                    >
                      Confirm Password
                    </InputLabel>
                    <Input
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Enter Here"
                      sx={{ width: "200px", color: "grey", marginTop: "20px" }}
                      id="input-with-icon-adornment"
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={Password} alt="password" className="icon" />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                //   disabled
                type="submit"
                sx={{
                  marginBottom: "50px",
                  width: "300px",
                  marginTop: "60px",
                  backgroundColor: "#00A6E8",
                  color: "white",
                }}
              >
                Done
              </Button>
            </form>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default NewPassword;
