import React from "react";
import DynamicForm from "./DynamicForm";

const fornData = [
  {
    basic: [
      {
        type: "file",
        props: {
          required: true,
          label: "Image",
          placeholder: "",
          name: "image",
          multiple: true,
          values: [],
          type: "defined",
          customValue: 0,
          renderConditionally: false,
          condition: {
            fieldName: "",
            fieldValue: "",
          },
          pattern: "",
          maxLength: "",
          minLength: "",
          minValue: "",
          maxValue: "",
          className: "form-control",
        },
      },
      {
        type: "text",
        props: {
          required: true,
          label: "Title",
          placeholder: "Type Here",
          name: "title",
          multiple: false,
          values: [],
          type: "defined",
          customValue: 0,
          renderConditionally: false,
          condition: {
            fieldName: "",
            fieldValue: "",
          },
          pattern: "",
          maxLength: "",
          minLength: "",
          minValue: "",
          maxValue: "",
          className: "form-control",
        },
      },
      {
        type: "text",
        props: {
          required: true,
          label: "Description",
          placeholder: "Type Here",
          name: "description",
          multiple: false,
          values: [],
          type: "defined",
          customValue: 0,
          renderConditionally: false,
          condition: {
            fieldName: "",
            fieldValue: "",
          },
          pattern: "",
          maxLength: "",
          minLength: "",
          minValue: "",
          maxValue: "",
          className: "form-control",
        },
      },
    ],
    details: [
      {
        type: "select",
        props: {
          required: true,
          label: "Price Type",
          placeholder: "",
          name: "price_type",
          multiple: false,
          values: [
            {
              label: "Fixed Price",
              value: "fixed_price",
              selected: false,
            },
            {
              label: "Negotiable",
              value: "negotiable",
              selected: false,
            },
            {
              label: "Upon Request",
              value: "upon_request",
              selected: false,
            },
            {
              label: "Free to Donate",
              value: "free_to_donate",
              selected: false,
            },
          ],
          type: "custom",
          customValue: 5,
          renderConditionally: false,
          condition: {
            fieldName: "",
            fieldValue: "",
          },
          pattern: "",
          maxLength: "",
          minLength: "",
          minValue: "",
          maxValue: "",
          className: "form-control",
        },
      },
      {
        type: "text",
        props: {
          required: true,
          label: "Price",
          placeholder: "",
          name: "price",
          multiple: false,
          values: [],
          type: "defined",
          customValue: 0,
          renderConditionally: false,
          condition: {
            fieldName: "",
            fieldValue: "",
          },
          pattern: "",
          maxLength: "",
          minLength: "",
          minValue: "",
          maxValue: "",
          className: "form-control",
        },
      },
      {
        type: "signature",
        props: {
          required: true,
          label: "Signature",
          placeholder: "",
          name: "signature",
          multiple: false,
          values: [],
          type: "defined",
          customValue: 0,
          renderConditionally: false,
          condition: {
            fieldName: "",
            fieldValue: "",
          },
          pattern: "",
          maxLength: "",
          minLength: "",
          minValue: "",
          maxValue: "",
          className: "form-control",
        },
      },
      {
        type: "text",
        props: {
          required: true,
          label: "Conditional Field",
          placeholder: "",
          name: "conditional_field",
          multiple: false,
          values: [],
          type: "defined",
          customValue: 0,
          renderConditionally: true,
          condition: {
            fieldName: "price_type",
            fieldValue: "negotiable",
          },
          pattern: "",
          maxLength: "",
          minLength: "",
          minValue: "",
          maxValue: "",
          className: "form-control",
        },
      },
    ],
  },
];

const Test = () => {
  return (
    <div>
      <DynamicForm formData={[...fornData[0].basic, ...fornData[0].details]} />
    </div>
  );
};

export default Test;
