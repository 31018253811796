import React, { useState } from "react";
import "./sidebar.css";
import {
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
const Styles = {
  menuItemStyle: {
    paddingLeft: "0",
    paddingRight: "0",
  },
};
const Sidebar = () => {
  const [openProfilePopup, setOpenProfilePopup] = useState(null);
  const endpoint = window.location.pathname;
  const handleCloseProfile = () => {
    setOpenProfilePopup(null);
  };

  return (
    <>
      <Paper
        sx={{
          direction: "column",
          marginLeft: "10px",
          marginTop: "15px",
          height: "98.3%",
        }}
      >
        <MenuList>
          <MenuItem sx={Styles?.menuItemStyle}>
            <ListItem
              sx={{
                bgcolor: endpoint === "/account" ? "#00A6E8" : "#fff",
                color: endpoint === "/account" ? "#fff" : "#00000",
              }}
              component={Link}
              to="/account"
              onClick={handleCloseProfile}
            >
              <ListItemText
                color="white"
                primary="My Account"
              />
            </ListItem>
          </MenuItem>
          <MenuItem sx={Styles?.menuItemStyle}>
            <ListItem
              sx={{
                bgcolor: endpoint === "/profile" ? "#00A6E8" : "#fff",
                color: endpoint === "/profile" ? "#fff" : "#00000",
              }}
              component={Link}
              to="/profile"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Profile" />
            </ListItem>
          </MenuItem>
          <MenuItem sx={Styles?.menuItemStyle}>
            <ListItem
              sx={{
                bgcolor: endpoint === "/my-ads" ? "#00A6E8" : "#fff",
                color: endpoint === "/my-ads" ? "#fff" : "#00000",
              }}
              component={Link}
              to="/my-ads"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="My Ads" />
            </ListItem>
          </MenuItem>
          <MenuItem sx={Styles?.menuItemStyle}>
            <ListItem
              sx={{
                bgcolor: endpoint === "/following" ? "#00A6E8" : "#fff",
                color: endpoint === "/following" ? "#fff" : "#00000",
              }}
              component={Link}
              to="/following"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Following" />
            </ListItem>
          </MenuItem>
          <MenuItem sx={Styles?.menuItemStyle}>
            <ListItem
              sx={{
                bgcolor: endpoint === "/favourite" ? "#00A6E8" : "#fff",
                color: endpoint === "/favourite" ? "#fff" : "#00000",
              }}
              component={Link}
              to="/favourite"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Favourite" />
            </ListItem>
          </MenuItem>
          <MenuItem sx={Styles?.menuItemStyle}>
            <ListItem
              sx={{
                bgcolor: endpoint === "/help" ? "#00A6E8" : "#fff",
                color: endpoint === "/help" ? "#fff" : "#00000",
              }}
              component={Link}
              to="/help"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Help" />
            </ListItem>
          </MenuItem>
          <MenuItem sx={Styles?.menuItemStyle}>
            <ListItem
              sx={{
                bgcolor:
                  endpoint === "/suggestions-complaints" ? "#00A6E8" : "#fff",
                color:
                  endpoint === "/suggestions-complaints" ? "#fff" : "#00000",
              }}
              component={Link}
              to="/suggestions-complaints"
              onClick={handleCloseProfile}
            >
              <ListItemText primary="Voucher" />
            </ListItem>
          </MenuItem>
        </MenuList>
      </Paper>
    </>
  );
};

export default Sidebar;
