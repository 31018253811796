import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Box, Typography, Grid, Avatar } from "@mui/material";
import styled from "@emotion/styled";

// Components
import CenterHeader from "../../../components/CenterHeader/CenterHeader";
import MarketPlace from "../../../components/PostNewAds/MarketPlace";

// Icon
import Store from "../../../assets/images/popularCategory/store.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryById } from "../../../store/helper/categorySlice";
import {
  fetchProductList,
  localPreviewProduct,
  Productpublish,
  uploadProduct,
} from "../../../store/product/productSlice";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #f5f5f5;
`;

const WhiteBox = styled(Box)`
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Heading = styled(Typography)`
  padding: 7px 0px 5px 20px;
  color: #000;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const ButtonContainer = styled(Box)`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 24px;
  margin-right: 24px;
`;

const AdsCategoryText = styled(Typography)`
  color: #707187;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const Buttons = styled(Button)`
  border-radius: 4px;
  border: 0.5px solid #d8d8df;
  background: ${(props) => (props.selected ? "#00A6E8" : "#fff")};
  color: ${(props) => (props.selected ? "white" : "#707187")};
  &:hover {
    background: ${(props) => (props.selected ? "#00A6E8" : "#f0f0f0")};
    color: ${(props) => (props.selected ? "white" : "#707187")};
  }
`;

const AdsButtons = styled(Button)`
  border-radius: 4px;
  border: 0.5px solid #d8d8df;
  background: ${(props) => (props.adsselected ? "#00A6E8" : "#fff")};
  color: ${(props) => (props.adsselected ? "white" : "#707187")};
  &:hover {
    background: ${(props) => (props.adsselected ? "#00A6E8" : "#f0f0f0")};
    color: ${(props) => (props.adsselected ? "white" : "#707187")};
  }
`;

const PostNewAdd = () => {
  const [selectedAdsTypeButton, setSelectedAdsTypeButton] = useState(null);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [currentSelectedCategoryId, setCurrentSelectedCategoryId] =
    useState(null); // Track selected category ID
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [showTemplate, setShowTemplate] = useState(false);
  const [btnDissabled, setBtnDissabled] = useState(true);
  const [visibleSections, setVisibleSections] = useState(["basic"]);
  const formHandleSubmitRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryData = useSelector((state) => state?.categoryReducer);
  const template = useSelector((state) => state.templateReducer || []);

  // Fetch subcategories on category button click
  const handleCategoryClick = (categoryData) => {
    setSelectedSubCategories([]);
    setShowTemplate(false);
    setSelectedMainCategory(categoryData);
    setCurrentSelectedCategoryId(categoryData?.id);
    dispatch(getCategoryById(categoryData?.id));
  };

  const handleNextClick = () => {
    if (formHandleSubmitRef.current) {
      // Trigger the hidden submit button
      formHandleSubmitRef.current.click();
    }
  };

  function extractFieldNamesBySection(data) {
    const sections = {
      details: {},
    };

    data.details.forEach((field) => {
      if (field.props && field.props.name) {
        sections.details[field.props.name] = null; // Use the field name as key, value as null
      }
    });
    return sections;
  }

  function organizeData(categoryStructure, data) {
    const organizedData = {};

    // Loop through each category in the category structure
    for (const category in categoryStructure) {
      if (
        typeof categoryStructure[category] === "object" &&
        categoryStructure[category] !== null
      ) {
        organizedData[category] = {};

        // Iterate over the keys in each category object
        for (const key in categoryStructure[category]) {
          organizedData[category][key] = data[key] || {
            label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the key for default label
          };
        }
      } else {
        console.error(
          `Expected an object for category: ${category}, but received:`,
          categoryStructure[category]
        );
        organizedData[category] = {}; // If not an object, initialize an empty object
      }
    }

    return organizedData;
  }

  const handleFormSubmit = (formData) => {
    // const simpleData = simplifyData(formData);

    // Dispatch the form data to the store
    const previewData = organizeData(
      extractFieldNamesBySection(template?.templateData),
      formData
    );
    const publishData = convertToFormData(formData);
    dispatch(Productpublish(publishData));
    //dispatch(uploadProduct(publishData))

    dispatch(localPreviewProduct(previewData));
    navigate("/post/preview");
    //dispatch(submitFormData(formData));

    // Navigate to the preview page (replace with actual navigation logic)
    // Example: navigate('/preview');
  };
  function convertToFormData(inputData) {
    const formData = new FormData();
  
    // Handle the "image" field separately
    if (Array.isArray(inputData.image.value)) {
      inputData.image.value.forEach((image) => {
        formData.append("images", image);
      });
    }
  
    // Create an object to hold all other fields in the same format
    const data = {};
  
    // Populate "data" with the same structure as inputData, excluding "image"
    Object.keys(inputData).forEach((key) => {
      if (key !== "image") {
        data[key] = inputData[key];
      }
    });
    const template_id = localStorage.getItem("template_id")
    data["template_id"]=template_id
    const templateType = localStorage.getItem("templateType")
    data["templateType"]=templateType
  
    // Append the structured "data" to FormData
    formData.append("data", JSON.stringify({details:data}));
  
    return formData;
  }
  
  // function convertToFormData(inputData) {
  //   const formData = new FormData();
  //   // Add images to the "images" key
  //   if (Array.isArray(inputData.image)) {
  //     inputData.image.forEach((image, index) => {
  //       formData.append(`images`, image);
  //     });
  //   }

  //   // Create a data object for all other fields
  //   const data = {};
  //   console.log(inputData);
    

  //   // Append serialized data to the "data" key
  //   formData.append("data", JSON.stringify({ details: data }));

  //   return formData;
  // }


  return (
    <>
      <CenterHeader />
      <Container>
        <WhiteBox>
          <Heading>Basic Information</Heading>
          <Divider />

          {/* Ads Type and Select Category */}
          <Grid container spacing={2} sx={{ mt: "24px", ml: "32px" }}>
            <Grid item xs={3}>
              <AdsCategoryText>Ads Type*</AdsCategoryText>
              <Box display="flex" gap="10px" mt="8px">
                <AdsButtons
                  adsselected={selectedAdsTypeButton === 0}
                  onClick={() => {
                    setSelectedAdsTypeButton(0);
                    setBtnDissabled(false);
                    setSelectedSubCategories([]);
                    setShowTemplate(false);
                  }}
                >
                  I Want to Sell
                </AdsButtons>
                <AdsButtons
                  adsselected={selectedAdsTypeButton === 1}
                  onClick={() => {
                    setSelectedAdsTypeButton(1);
                    setBtnDissabled(false);
                    setSelectedSubCategories([]);
                    setShowTemplate(false);
                  }}
                >
                  I am Looking for
                </AdsButtons>
              </Box>
            </Grid>

            {/* Select Category */}
            <Grid item xs={9}>
              <AdsCategoryText>Select Category</AdsCategoryText>
              <Box display="flex" gap="6px" mt="8px">
                {categoryData?.mainCategorydata?.map((item) => (
                  <Buttons
                    key={item?.id + item.name}
                    selected={selectedMainCategory?.id === item?.id}
                    onClick={() => handleCategoryClick(item)}
                    disabled={btnDissabled}
                    startIcon={
                      <Avatar
                        src={Store}
                        sx={{
                          height: "20px",
                          width: "20px",
                          marginRight: "-5px",
                        }}
                      />
                    }
                  >
                    {item?.name}
                  </Buttons>
                ))}
              </Box>
            </Grid>
          </Grid>

          {/* Contents show */}
          <Box sx={{ mt: "24px", ml: "50px", mr: "50px" }}>
            <Divider />
            {selectedMainCategory?.id && (
              <MarketPlace
                currentSelectedCategoryId={currentSelectedCategoryId}
                setCurrentSelectedCategoryId={setCurrentSelectedCategoryId}
                selectedSubCategories={selectedSubCategories}
                setSelectedSubCategories={setSelectedSubCategories}
                selectedMainCategory={selectedMainCategory}
                selectedAdsTypeButton={selectedAdsTypeButton}
                setShowTemplate={setShowTemplate}
                showTemplate={showTemplate}
                visibleSections={visibleSections}
                setVisibleSections={setVisibleSections}
                formHandleSubmitRef={formHandleSubmitRef}
                handleFormSubmit={handleFormSubmit}
                handleNextClick={handleNextClick}
              />
            )}
          </Box>

          {/* Cancel and Next buttons */}
          <ButtonContainer>
            <Button variant="contained" color="secondary">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextClick}
            >
              Next
            </Button>
          </ButtonContainer>
        </WhiteBox>
      </Container>
    </>
  );
};

export default PostNewAdd;
