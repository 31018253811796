import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { loginUser } from "../../../store/auth/authSlice";
import { encryptPassword } from "../../../utils/encryption";
import { toast } from "react-toastify";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import Email from "../../../assets/images/LoginImages/mail.png";
import Password from "../../../assets/images/LoginImages/password.png";
import "./login.css";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Personal email is required"),
  password: yup
    .string()
    .required("This field is required")
    // .min(8, "Password must be 8 or more characters")
    // .matches(
    //   /(?=.*[a-z])(?=.*[A-Z])\w+/,
    //   "Password should contain at least one uppercase and lowercase character"
    // )
    // .matches(/\d/, "Password should contain at least one number")
    // .matches(
    //   /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
    //   "Password should contain at least one special character"
    // ),
});

const Login = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const encryptedPassword = encryptPassword.encrypt(values.password);
      const reqBody = { email: values.email, password: encryptedPassword };

      const res = await dispatch(loginUser(reqBody));
      if (res?.data?.status) {
        console.log(res?.data?.status);
        
        toast.success("Login successful!");
        navigate("/home");
      } else {
        toast.error(res?.data?.message || "Login failed");
      }
      resetForm();
    },
  });

  return (
    <Box
      sx={{
        border: "0.5px solid #D8D8DF",
        width: 936,
        minHeight: 452,
        bgcolor: "#FFF",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        overflow: "hidden",
      }}
    >
      <Box sx={{ padding: "48px" }}>
        <h1 className="headline">Welcome to VerkaufAllen!</h1>
        <p className="subtitle">
          Please enter your credentials to access your account.
        </p>
        <form onSubmit={formik.handleSubmit} className="form">
          <Grid container spacing={3}>
            {["email", "password"].map((field, idx) => (
              <Grid item xs={12} sm={3} key={field}>
                <FormControl
                  variant="standard"
                  error={formik.touched[field] && Boolean(formik.errors[field])}
                >
                  <InputLabel htmlFor={field} sx={{ marginLeft: "30px" }}>
                    {field === "email" ? "Email Id" : "Password"}
                  </InputLabel>
                  <Input
                    id={field}
                    type={
                      field === "password"
                        ? showPassword
                          ? "text"
                          : "password"
                        : "email"
                    }
                    name={field}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[field]}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={field === "email" ? Email : Password}
                          alt={field}
                          className="icon"
                        />
                      </InputAdornment>
                    }
                    endAdornment={
                      field === "password" && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((prev) => !prev)}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                  {formik.touched[field] && formik.errors[field] && (
                    <FormHelperText>{formik.errors[field]}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={3} marginTop={-2}>
            <Grid item xs={12} sm={3}>
              <Button
                onClick={() => navigate("/forgot-password")}
                sx={{
                  marginTop: "20px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: "#00A6E8",
                }}
              >
                Forgot Password?
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} marginTop={3}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: "300px",
                  textTransform: "none",
                  backgroundColor:
                    formik.isValid && formik.dirty ? "#00A6E8" : "gray",
                  color: "white",
                }}
                disabled={!(formik.isValid && formik.dirty)}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
        <div className="bottom">
          <div className="otherOption">
            <p> Or continue with</p>
          </div>
          <Divider className="horizontalLine" />
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            marginLeft={2}
          >
            {[
              { icon: <GoogleIcon />, label: "Continue With Google" },
              {
                icon: <FacebookOutlinedIcon />,
                label: "Continue With Facebook",
              },
              { icon: <AppleIcon />, label: "Continue With Apple" },
            ].map(({ icon, label }) => (
              <Button
                key={label}
                startIcon={icon}
                sx={{
                  backgroundColor: "#eeeeee",
                  color: "black",
                  textTransform: "none",
                }}
              >
                {label}
              </Button>
            ))}
          </Stack>
        </div>
      </Box>
    </Box>
  );
};

export default Login;
