import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import styled from "@emotion/styled";

// Icons
import DropdownSelect from "./DropdownSelect";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryById } from "../../store/helper/categorySlice";
import { fetchTemplate } from "../../store/template/templateSlice";
import DynamicForm from "../DynamicForm/DynamicForm";

// styles
const Text = styled(Typography)`
  color: #26252c;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const HRLine = styled(Divider)`
  margin-top: 24px;
  margin-bottom: 24px;
`;

function MarketPlace({
  setCurrentSelectedCategoryId,
  currentSelectedCategoryId,
  selectedSubCategories,
  setSelectedSubCategories,
  selectedMainCategory,
  selectedAdsTypeButton,
  setShowTemplate,
  showTemplate,
  visibleSections,
  setVisibleSections,
  formHandleSubmitRef,
  handleFormSubmit,
  handleNextClick,
}) {
  const categoriesData = useSelector((state) => state.categoryReducer || []);
  const template = useSelector((state) => state.templateReducer || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  // Track visible form sections
  const dispatch = useDispatch();

  const handleClick = (event) => {
    const target = event.currentTarget; // Capture the event target immediately
    setAnchorEl((prevAnchorEl) => {
      const newAnchorEl = prevAnchorEl ? null : target; // Use the captured target
      return newAnchorEl;
    });
  };
  

  

  const handleSelect = (option) => {
    dispatch(getCategoryById(option?.id));
    setSelectedSubCategories([...selectedSubCategories, option]);
    setCurrentSelectedCategoryId(option?.id);
    setSearch("");

    const subcategories = categoriesData?.subCategoryMap[option?.id] || [];

    if (subcategories.length === 0) {
      const template_id = [
        selectedMainCategory,
        ...selectedSubCategories,
        option,
      ]
        ?.map((item) => item.id)
        .join("_");
        localStorage.setItem("template_id",template_id);
        localStorage.setItem("templateType",selectedAdsTypeButton);

      dispatch(
        fetchTemplate({ template_id, templateType: selectedAdsTypeButton })
      ).then(() => setShowTemplate(true));
      setAnchorEl(null);
    }
  };

  const subcategories =
    categoriesData?.subCategoryMap[currentSelectedCategoryId] || [];

  return (
    <Box sx={{ mt: "24px" }}>
      <Text>Select Sub Category</Text>
      <DropdownSelect
        currentSelectedCategoryId={currentSelectedCategoryId}
        setCurrentSelectedCategoryId={setCurrentSelectedCategoryId}
        setSelectedSubCategories={setSelectedSubCategories}
        selectedSubCategories={selectedSubCategories}
        setSearch={setSearch}
        search={search}
        anchorEl={anchorEl}
        subcategories={subcategories}
        handleSelect={handleSelect}
        handleClick={handleClick}
      />

      <HRLine />
    
      {/* Render visible sections dynamically */}
      {showTemplate && visibleSections.includes("basic") && (
        <DynamicForm
          formData={[
            ...(template?.templateData?.details || []),
          ]}
          handleFormSubmit={handleFormSubmit}
          formHandleSubmitRef={formHandleSubmitRef}
          handleNextClick={handleNextClick}
        />
      )}
    </Box>
  );
}

export default MarketPlace;
