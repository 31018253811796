import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ComplexSelectPopup from "./ComplexSelectPopup";

const ComplexSelect = ({ field, values, setFieldValue }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayValues, setDisplayValues] = useState([]); // For showing selected values in TextField after save
  const [tempValues, setTempValues] = useState([]); // Temporary values for popup selection

  const handleDropdownClick = () => {
    setIsModalOpen(true);
    setTempValues(displayValues); // Load current display values into temporary state on open
  };

  const handlePopupClose = () => {
    setIsModalOpen(false);
  };

  const handleValueSelect = (value) => {
    if (field.props.multiple) {
      // Add value to temporary state for multiple selection
      setTempValues((prev) => [...prev, value]);
    } else {
      // Set single value in temporary state
      setTempValues([value]);
    }
  };

  const handleSave = (formattedData = []) => {
    // Update display values and setFieldValue only on save
    if (formattedData?.length > 0) {
      setDisplayValues(formattedData);
      setFieldValue(field.props.name, formattedData.join(", "));
    } else {
      setDisplayValues(tempValues);
      setFieldValue(field.props.name, tempValues.join(", "));
    }
    setIsModalOpen(false);
  };
  return (
    <>
      <TextField
        fullWidth
        variant="standard"
        name={field.props.name}
        label={field.props.label}
        value={displayValues.join(", ")}
        onClick={handleDropdownClick}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon />
            </InputAdornment>
          ),
        }}
      />

      {/* Render the Popup Modal */}
      {isModalOpen && (
        <ComplexSelectPopup
          open={isModalOpen}
          onClose={handlePopupClose}
          childrenFields={field.props.childrenFields}
          multiple={field.props.multiple}
          onSelect={handleValueSelect} // Update temporary values on selection
          onSave={handleSave} // Only save to display values on save
        />
      )}
    </>
  );
};

export default ComplexSelect;
