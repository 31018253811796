import React from "react";

// Images
import Calendar from "../../assets/images/AdsPage/calendar.png";
import View from "../../assets/images/AdsPage/view.png";
import Cart from "../../assets/images/AdsPage/favorite-remove.png";
import Location from "../../assets/images/AdsPage/location-04.png";
import Ellipse from "../../assets/images/AdsPage/Ellipse 3.png"

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";

const CHIP_COLORS = {
  Active: "success",
  Inactive: "default",
};

function FavouriteCard({ products }) {

  // const theme = useTheme();
  return (
    <>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={2}>
          {products && products.length > 0 ? (
            products.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Card
                  sx={{
                    display: "flex",
                    position: "relative",
                    boxShadow: "none",
                    border: "none",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={item.image}
                    alt={item.name}
                  />
                  <Box
                    sx={{ display: "flex", flexDirection: "column", flex: 1 }}
                  >
                    <Box sx={{ position: "absolute", right: 8 }}>
                      <IconButton aria-label="more options">
                        <img src={Cart} alt="Cart" />
                      </IconButton>
                    </Box>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{ marginTop: "-10px", fontSize: "20px" }}
                      >
                        € {item.previousPrice}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        <Chip
                          sx={{
                            marginLeft: "85px",
                            fontSize: "10px",
                            height: "20px",
                            marginTop: "-65px",
                          }}
                          color={CHIP_COLORS[item.status]}
                          label={item.status}
                        />
                      </Typography>
                      <Typography
                        component="div"
                        sx={{
                          marginTop: "-30px",
                          fontSize: "18px",
                          fontWeight: 500,
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                          variant: "subtitle1",
                          color: "text.secondary",
                        }}
                      >
                        <Typography>
                          <img
                            src={Location}
                            alt="Location"
                            style={{ marginTop: "5px" }}
                          />
                        </Typography>
                        <Typography fontSize={14}>Area</Typography>
                        <Typography
                          sx={{
                            height: "5px",
                            width: "5px",
                            marginBottom: "23px",
                          }}
                        >
                          <img src={Ellipse} alt="Ellipse" />
                        </Typography>
                        <Typography fontSize={14}>Innere Stadt</Typography>
                        <Typography
                          sx={{
                            height: "5px",
                            width: "5px",
                            marginBottom: "23px",
                          }}
                        >
                          <img src={Ellipse} alt="Ellipse" />
                        </Typography>
                        <Typography fontSize={14}>Vienna</Typography>
                      </Box>
                    </CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        pl: 1,
                        pb: 1,
                      }}
                    >
                      <img src={View} alt="view" className="icon" />
                      <Typography marginRight={2} fontSize={14}>
                        {item.views}
                      </Typography>
                      <img src={Calendar} alt="view" className="icon" />
                      <Typography fontSize={14}>{item.days}</Typography>
                    </Box>
                    <Box sx={{ position: "absolute", top: 95, right: 22 }}>
                      <Typography variant="body1" color="gray" fontSize={12}>
                        3d
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Box sx={{ position: "relative", top: 100, left: 420 }}>
              <Typography variant="h6">No Favourite found</Typography>
            </Box>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default FavouriteCard;
