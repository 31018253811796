import React from "react";
import CenterHeader from "../../../components/CenterHeader/CenterHeader";
import { KeyboardBackspace } from "@mui/icons-material";
import { Box, Button, Grid, Typography, Divider, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// Images
import { formatString } from "../../../utils/helper";
import ImageViewer from "../../../components/common/ImageViewer";
import { uploadProduct } from "../../../store/product/productSlice";
import { useNavigate } from "react-router-dom";

const detailsText = {
  marginTop: "24px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "115%",
};

const detailsText2 = {
  marginTop: "12px",
  color: "#707187",
  fontFamily: "Work Sans",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "115%",
};

const dashedLine = {
  borderStyle: "dashed",
  width: "100%",
  height: "0.2px",
  border: "1px dashed #D8D8DF",
  marginTop: "12px",
  marginBottom: "12px",
};

const descriptive = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "115%",
};

const descriptiveText = {
  marginTop: "8px",
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "18px",
};

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
`;

const WhiteBox = styled(Box)`
  background-color: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const Heading = styled(Typography)`
  padding: 7px 0px 5px 20px;
  color: #000;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
`;

// Helper function to split data into chunks of 2, excluding specific keys
const chunkData = (data) => {
  if (!data || Object.keys(data).length === 0) return [];

  const excludedKeys = [
    "price_type",
    "descriptive_paragraph",
    "description",
    "title",
    "image",
  ]; // Exclude these keys
  const entries = Object.entries(data).filter(
    ([key]) => !excludedKeys.includes(key) // Filter out excluded keys
  );

  const chunks = [];
  for (let i = 0; i < entries.length; i += 2) {
    chunks.push(entries.slice(i, i + 2));
  }
  return chunks;
};

// Helper function to transform values
const transformValue = (value) => {
  if (typeof value === "string") {
    // Convert snake_case to Title Case
    return value
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  if (Array.isArray(value)) {
    // If the value is an array, join the values with commas
    return value.length > 0 ? value.join(", ") : "N/A";
  }

  if (typeof value === "object" && value !== null) {
    let formattedValue = "";

    // Loop through all keys in the object to format them
    Object.keys(value).forEach((key) => {
      const val = value[key];
      // Check if the value is a number (you can add more checks if needed)
      if (typeof val === "number") {
        // Format the key and value dynamically (e.g., "year: 5 years")
        formattedValue += `${val} ${key}${val > 1 ? "s" : ""}, `;
      }
    });

    // Remove the trailing comma and space if any
    return formattedValue ? formattedValue.slice(0, -2) : "N/A";
  }

  return value || "N/A";
};

const SectionName = ({ sectionData }) => {
  const dataChunks = chunkData(sectionData);

  return (
    <Grid container spacing={"16px"}>
      {dataChunks.map((chunk, index) => (
        <Grid item xs={12} key={index}>
          <Grid container spacing={2}>
            {chunk.map(([key, { label, value }], idx) => (
              <Grid item xs={12} sm={6} key={idx}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ ...detailsText2, fontWeight: 700 }}>
                    {label}
                  </Typography>
                  <Typography textAlign={"end"} sx={detailsText2}>
                    {transformValue(value) || "N/A"}
                  </Typography>
                </Box>
                <div style={dashedLine} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const PreviewPage = () => {
  const previewProductData = useSelector(
    (state) => state?.productReducer?.localPreviewProductData
  );
  const publishData = useSelector(
    (state) => state?.productReducer?.publishData
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePublish = () => {
    dispatch(uploadProduct(publishData)).then(() => {
      navigate("/home");
    });
  };


  return (
    <>
      <CenterHeader />
      <Container>
        <WhiteBox>
          <Heading sx={{ marginLeft: "-25px" }}>
            <Button
              sx={{
                "&:hover": {
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                },
              }}
            >
              <KeyboardBackspace sx={{ color: "black" }} />
            </Button>
            Preview
          </Heading>
          <Divider />
          <Box paddingLeft="32px" marginTop={"24px"} marginRight={"24px"}>
            <Grid container spacing={"16px"}>
              {/* Selected images */}
              <Grid item xs={12} sm={6} md={5}>
                <ImageViewer
                  images={previewProductData?.details?.image?.value}
                  altText="Local File Image"
                  isFile={true}
                />
              </Grid>

              {/* Data */}
              <Grid item xs={12} sm={6} md={7}>
                {/* Fixed Price text */}
                <Typography
                  sx={{
                    color: "#707187",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "110%",
                  }}
                >
                  {previewProductData?.details?.price_type?.value
                    ? formatString(previewProductData.details.price_type.value)
                    : null}
                </Typography>

                <Typography
                  sx={{
                    mt: "0.5rem",
                    color: "#707187",
                    fontFamily: "Work Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "110%",
                  }}
                >
                  {previewProductData?.details?.price?.value
                    ? `€ ${previewProductData.details.price.value}`
                    : null}
                </Typography>

                <Box mt={"0.5rem"}>
                  <Typography
                    variant="h6"
                    sx={{
                      alignSelf: "center",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    {previewProductData?.details?.title?.value}
                  </Typography>
                </Box>

                <Box mt={"0.5rem"}>
                  <Typography
                    sx={{
                      alignSelf: "start",
                      fontSize: "0.85rem",
                      lineHeight: "normal",
                    }}
                  >
                    {previewProductData?.details?.description?.value}
                  </Typography>
                </Box>

                {/* State pin and village or district */}

                {/* Nick name and user profile */}

                {/* Details */}
                <Typography sx={detailsText}>Details</Typography>

                {/* Section name */}
                <Box sx={{ marginTop: "16px" }}>
                  <SectionName
                    sectionData={{
                      ...previewProductData?.details, // Spread details if they exist
                    }}
                  />
                </Box>
              </Grid>

              {/* Descriptive Paragraph */}
              <Box width={"100%"} marginTop={"19px"} marginLeft={"24px"}>
                <Typography sx={descriptive}>Descriptive Paragraph</Typography>

                <Typography sx={descriptiveText}>
                  {previewProductData?.details?.descriptive_paragraph?.value}
                </Typography>
              </Box>

              <Divider sx={{ marginTop: "24px" }} />

              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"flex-end"}
                gap={"1rem"}
                mb={"1rem"}
                mt={"1rem"}
              >
                <Button variant="outlined" color="secondary">
                  Cancel
                </Button>
                <Button
                  onClick={handlePublish}
                  variant="contained"
                  color="primary"
                >
                  Publish
                </Button>
              </Box>
            </Grid>
          </Box>
        </WhiteBox>
      </Container>
    </>
  );
};

export default PreviewPage;

//   {/* State pin and village or district */}
//   <Box
//   sx={{
//     display: "flex",
//     justifyContent: "space-between",
//     marginTop: "24px",
//   }}
// >
//   {/* STate pin dist */}
//   <Box sx={{ display: "flex", gap: "8px" }}>
//     {/* Image and state */}
//     <Box
//       sx={{ display: "flex", gap: "4px", marginRight: "8px" }}
//     >
//       <img src={location} alt="location icon" />
//       <Typography sx={state_zip_dist}>State</Typography>
//     </Box>

//     {/* Dot */}
//     <div style={dotStyle} />

//     <Typography sx={state_zip_dist}>1010</Typography>

//     {/* Dot */}
//     <div style={dotStyle} />

//     <Typography sx={state_zip_dist}>
//       Village or district
//     </Typography>
//   </Box>

//   {/* Sponsored */}
//   <Box
//     sx={{
//       display: "flex",
//       gap: "4px",
//     }}
//   >
//     <Typography sx={sponsoredText}>Sponsored</Typography>
//     <Typography sx={sponsoredText}>Sponsored</Typography>
//   </Box>
// </Box>

//  {/* Nick name and user profile */}
//  <Button
//  display={"flex"}
//  sx={{
//    alignSelf: "center",
//    borderRadius: "4px",
//    border: "1px solid #D8D8DF",
//    background: "#FFF",
//    width: "328px",
//    padding: "12px",
//    marginTop: "24px",
//  }}
// >
//  <Avatar sx={{ width: "28px", height: "28px" }} />

//  {/* Name and Image */}
//  <Box
//    sx={{
//      display: "flex",
//      justifyContent: "space-between",
//      width: "100%",
//      alignItems: "center",
//      marginLeft: "8px",
//    }}
//  >
//    <Box>
//      <Typography sx={nickNameText}>Nick Name</Typography>
//      <Typography sx={sinceText}>Since 2024</Typography>
//    </Box>
//    <Box sx={{ display: "flex", gap: "2px" }}>
//      <Typography sx={otherAdsText}>Other Ads</Typography>
//      <img
//        src={drop}
//        alt="drop icon"
//        style={{ width: "12px", height: "12px" }}
//      />
//    </Box>
//  </Box>
// </Button>
