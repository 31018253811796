import React, { useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Box,
  ListItemText,
  Checkbox,
} from "@mui/material";
import UploadImages from "./UploadImages";
import ComplexSelect from "./ComplexSelect";

// Create Yup validation schema
const createValidationSchema = (formData, values) => {
  const schemaShape = {};

  formData.forEach((field) => {
    const { name, required, label, options, multiple } = field.props;

    // Determine if the field should be rendered (conditionally)
    const shouldRender = shouldRenderField(field, values);
    if (shouldRender) {
      switch (field.type) {
        case "text":
          schemaShape[name] = required
            ? Yup.string().required(`${label} is required`)
            : Yup.string();
          break;
        case "number":
        case "price":
          schemaShape[name] = required
            ? Yup.number()
                .typeError(`${label} must be a number`)
                .required(`${label} is required`)
                .positive(`${label} must be a positive number`)
                .integer(`${label} must be an integer`)
            : Yup.number()
                .typeError(`${label} must be a number`)
                .positive(`${label} must be positive`)
                .integer(`${label} must be an integer`);
          break;
        case "select":
          if (multiple) {
            schemaShape[name] = required
              ? Yup.array()
                  .min(1, `${label} is required`) // Ensure at least one item is selected
                  .of(Yup.string().required())
              : Yup.array().nullable();
          } else {
            schemaShape[name] = required
              ? Yup.string().required(`${label} is required`)
              : Yup.string();
          }
          break;
        case "textarea":
          schemaShape[name] = required
            ? Yup.string().required(`${label} is required`)
            : Yup.string();
          break;
        case "file":
          schemaShape[name] = required
            ? Yup.array()
                .required("At least one image is required")
                .min(1, "At least one image is required")
            : Yup.array().nullable();
          break;
          case "date":
            let dateSchema = Yup.date();
            if (required) {
              dateSchema = dateSchema.required(`${label} is required`);
            }
          
            // Adjust to only use maxDate for past dates
            if (field.props.minLength) {
              const maxPastDate = new Date();
              maxPastDate.setDate(maxPastDate.getDate() - field.props.minLength);
              dateSchema = dateSchema.max(
                maxPastDate,
                `${label} must be at least ${field.props.minLength} days from today`
              );
            }
          
            if (field.props.maxLength) {
              const maxFutureDate = new Date();
              maxFutureDate.setDate(maxFutureDate.getDate() + field.props.maxLength);
              dateSchema = dateSchema.max(
                maxFutureDate,
                `${label} must be within ${field.props.maxLength} days from today`
              );
            }
          
            schemaShape[name] = dateSchema;
            break;
          
          
          
          
        // case "date":
        //   schemaShape[name] = required
        //     ? Yup.date().required(`${label} is required`)
        //     : Yup.date();
        //   break;
        case "radiogroup":
          schemaShape[name] = required
            ? Yup.string().required(`${label} is required`)
            : Yup.string();
          break;
        case "checkbox":
          schemaShape[name] = required
            ? Yup.boolean().oneOf([true], `${label} is required`)
            : Yup.boolean();
          break;
        case "checkboxgroup":
          schemaShape[name] = required
            ? Yup.array()
                .min(1, `At least one ${label} must be selected`)
                .of(Yup.string().required())
            : Yup.array().nullable();
          break;
        default:
          break;
      }
    }
  });

  return Yup.object().shape(schemaShape);
};

// Conditional render logic
const shouldRenderField = (field, values) => {
  if (field.props.renderConditionally) {
    const { fieldName, fieldValue } = field.props.condition;
    return values[fieldName] === fieldValue;
  }
  return true;
};

const DynamicForm = ({ formData, handleFormSubmit, formHandleSubmitRef }) => {
  const initialValues = useMemo(() => {
    if (!formData?.length) return {}; // Guard clause to handle initial empty formData
    return formData.reduce((acc, field) => {
      const { name, defaultValue, type } = field.props;
      if (type === "ageyearmonth") {
        acc[name] = {
          year: defaultValue?.year || "",
          month: defaultValue?.month || "",
        };
      } else {
        acc[name] = type === "file" ? [] : defaultValue || "";
      }
      return acc;
    }, {});
  }, [formData]);
  
  // Generate validation schema
  const validate = async (values) => {
    const schema = createValidationSchema(formData, values);
    try {
      await schema.validate(values, { abortEarly: false });
      return {};
    } catch (err) {
      return err.inner.reduce((acc, validationError) => {
        acc[validationError.path] = validationError.message;
        return acc;
      }, {});
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={(values) => {
        const valuesWithLabels = formData.reduce((acc, field) => {
          const { name, label } = field.props;
          acc[name] = {
            value: values[name],
            label: label,
          };
          return acc;
        }, {});

        handleFormSubmit(valuesWithLabels);
      }}
    >
      {({ handleChange, values, setFieldValue }) => (
        <Form>
          {formData.map((field) => {
            if (!shouldRenderField(field, values)) {
              return null;
            }
            const { name, label, placeholder, values: options } = field.props;
            const { type } = field;

            switch (type) {
              case "text":
              case "number":
              case "price":
                return (
                  <Box key={name} mb={"0.85rem"}>
                    <Field
                      as={TextField}
                      fullWidth
                      name={name}
                      label={label}
                      type={type}
                      placeholder={placeholder}
                      value={values[name]}
                      onChange={handleChange}
                      helperText={
                        <ErrorMessage
                          name={name}
                          component="div"
                          style={{ color: "red" }}
                        />
                      }
                      variant="standard"
                    />
                  </Box>
                );
              case "select":
                return (
                  <Box key={name} mb={"0.85rem"}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>{label}</InputLabel>
                      <Select
                        label={label}
                        multiple={field.props.multiple} // Check if multiple is true
                        name={name}
                        value={values[name] || []} // Initialize with an array if multiple is true
                        onChange={handleChange}
                        renderValue={(selected) =>
                          field.props.multiple ? selected.join(", ") : selected
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {options.map((option, i) => (
                          <MenuItem key={i} value={option.value}>
                            {field.props.multiple ? (
                              <>
                                <Field
                                  as={Checkbox}
                                  checked={values[name]?.includes(option.value)}
                                />
                                <ListItemText primary={option.label} />
                              </>
                            ) : (
                              option.label
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage
                        name={name}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </FormControl>
                  </Box>
                );

              case "color":
                return (
                  <Box key={name} mb={"0.85rem"}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>{label}</InputLabel>
                      <Select
                        name={name}
                        value={values[name]}
                        onChange={handleChange}
                        renderValue={(selected) => {
                          const selectedOption = options.find(
                            (option) => option.value === selected
                          );
                          return selectedOption ? (
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                style={{
                                  height: "1rem",
                                  width: "1rem",
                                  backgroundColor: `#${selectedOption?.hexValue}`,
                                  display: "inline-block",
                                  marginRight: "0.5rem",
                                }}
                              ></span>
                              {selectedOption.label}
                            </span>
                          ) : (
                            ""
                          ); // Show swatch and label when selected
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <span
                              style={{
                                height: "1rem",
                                width: "1rem",
                                backgroundColor: `#${option?.hexValue}`,
                                display: "inline-block",
                                marginRight: "0.5rem", // Add gap here
                              }}
                            ></span>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage
                        name={name}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </FormControl>
                  </Box>
                );

              case "textarea":
                return (
                  <Box key={name} mb={"0.85rem"}>
                    <Field
                      as={TextField}
                      fullWidth
                      multiline
                      rows={4}
                      name={name}
                      label={label}
                      placeholder={placeholder}
                      value={values[name]}
                      onChange={handleChange}
                      helperText={
                        <ErrorMessage
                          name={name}
                          component="div"
                          style={{ color: "red" }}
                        />
                      }
                    />
                  </Box>
                );
              case "file":
                return (
                  <Box key={name} mb={"0.85rem"}>
                    <UploadImages
                      onImagesSelected={(files) => {
                        handleChange({
                          target: { name, value: files },
                        });
                      }}
                      selectedImages={values[name] || []}
                    />
                    <ErrorMessage
                      name={name}
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Box>
                );
              case "date":
                const minDate = field.props.pastDate
                  ? "1900-01-01"
                  : new Date().toISOString().split("T")[0];
                const maxDate = field.props.futureDate
                  ? "2100-12-31"
                  : new Date().toISOString().split("T")[0]; // Today for max date if futureDate is not true
                return (
                  <Box key={name} mb={"0.85rem"}>
                    <Field
                      as={TextField}
                      fullWidth
                      name={name}
                      label={label}
                      type="date"
                      value={values[name]}
                      onChange={handleChange}
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: minDate,
                        max: maxDate,
                      }}
                    />
                    <ErrorMessage
                      name={name}
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Box>
                );
                case "radiogroup":
                  return (
                    <Box key={name} mb={"0.85rem"}>
                      <FormLabel>{label}</FormLabel>
                      {field.props.viewType === "button" ? (
                        // Button-style view
                        <Box display="flex" gap={1} mt={"0.4rem"}>
                          {options.map((option) => (
                            <Button
                            sx={{textTransform:"none"}}
                              key={option.value}
                              variant={values[name] === option.value ? "contained" : "outlined"}
                              onClick={() => setFieldValue(name, option.value)}
                            >
                              {option.label}
                            </Button>
                          ))}
                        </Box>
                      ) : (
                        // Default radio button view
                        <Field name={name}>
                          {({ field }) => (
                            <RadioGroup {...field} row>
                              {options.map((option) => (
                                <FormControlLabel
                                  key={option.value}
                                  control={<Radio />}
                                  label={option.label}
                                  value={option.value}
                                />
                              ))}
                            </RadioGroup>
                          )}
                        </Field>
                      )}
                      <ErrorMessage
                        name={name}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Box>
                  );
                
              case "checkbox":
                return (
                  <Box key={name} mb={"0.85rem"}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name={name}
                          checked={values[name] || false} // Checkbox is true or false
                          onChange={handleChange}
                        />
                      }
                      label={label}
                    />
                    <ErrorMessage
                      name={name}
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Box>
                );
              case "checkboxgroup":
                return (
                  <Box key={name} mb={"0.85rem"}>
                    <FormLabel>{label}</FormLabel>
                    {options.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        control={
                          <Field
                            as={Checkbox}
                            name={name}
                            value={option.value}
                            checked={values[name]?.includes(option.value)} // If option is selected
                            onChange={handleChange}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                    <ErrorMessage
                      name={name}
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Box>
                );
              case "ageyearmonth":
                return (
                  <Box key={name} mb={"0.85rem"}>
                  <FormLabel>{label || "Age"}</FormLabel>
                  <Box display="flex" alignItems="center" gap="1rem">
                    <TextField
                      label="Year"
                      name={`${name}.year`}
                      value={values[name]?.year || ""}
                      onChange={handleChange}
                      variant="standard"
                      type="number"
                      fullWidth
                    />
                    <TextField
                      label="Month"
                      name={`${name}.month`}
                      value={values[name]?.month || ""}
                      onChange={handleChange}
                      variant="standard"
                      type="number"
                      fullWidth
                    />
                  </Box>
                  {/* Inline error message */}
                  {(values[name]?.year === 0||values[name]?.year === ""||!values[name]?.year) && values[name]?.month < 6 && (
                    <div style={{ color: "red" }}>
                      Age should be 6 month old
                    </div>
                  )}
                  <ErrorMessage
                    name={name}
                    component="div"
                    style={{ color: "red" }}
                  />
                </Box>
              );
            
              case "complexSelect":
                return (
                  <Box key={name} mb={"0.85rem"}>
                    <ComplexSelect
                      field={field} // Pass the entire field to ComplexSelect
                      values={values[name]}
                      setFieldValue={setFieldValue} // Update Formik's field value
                    />
                  </Box>
                );
              default:
                return null;
            }
          })}
          {/* Add a submit button if needed */}
          <Button
            ref={formHandleSubmitRef}
            sx={{ display: "none" }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default DynamicForm;

// {
//   "name": "Pets >> Dog >> Siberian Husky",
//   "templateId": "12_24_50",
//   "templateType": 0,
//   "basic": [
//       {
//           "type": "file",
//           "props": {
//               "required": true,
//               "label": "Image",
//               "placeholder": "",
//               "name": "image",
//               "multiple": true,
//               "values": [],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "text",
//           "props": {
//               "required": true,
//               "label": "Title",
//               "placeholder": "Enter Here",
//               "name": "title",
//               "multiple": false,
//               "values": [],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "text",
//           "props": {
//               "required": true,
//               "label": "Descrition",
//               "placeholder": "Enter Here",
//               "name": "descrition",
//               "multiple": false,
//               "values": [],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       }
//   ],
//   "details": [
//       {
//           "type": "select",
//           "props": {
//               "required": true,
//               "label": "Price Type",
//               "placeholder": "Select",
//               "name": "price_type",
//               "multiple": false,
//               "values": [
//                   {
//                       "label": "Fixed Priced",
//                       "value": "fixed_priced",
//                       "selected": true
//                   },
//                   {
//                       "label": "Negotiable",
//                       "value": "negotiable",
//                       "selected": false
//                   },
//                   {
//                       "label": "Upon Request",
//                       "value": "upon_request",
//                       "selected": false
//                   },
//                   {
//                       "label": "Free to Donate",
//                       "value": "free_to_donate",
//                       "selected": false
//                   }
//               ],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "number",
//           "props": {
//               "required": true,
//               "label": "Price",
//               "placeholder": "Enter Here",
//               "name": "price",
//               "multiple": false,
//               "values": [],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "select",
//           "props": {
//               "required": true,
//               "label": "Provider",
//               "placeholder": "Select",
//               "name": "provider",
//               "multiple": false,
//               "values": [
//                   {
//                       "label": "Tierheim",
//                       "value": "tierheim",
//                       "selected": false
//                   },
//                   {
//                       "label": "Animal protection association",
//                       "value": "animal_protection_association",
//                       "selected": false
//                   },
//                   {
//                       "label": "Agriculture",
//                       "value": "agriculture",
//                       "selected": false
//                   },
//                   {
//                       "label": "Breeder",
//                       "value": "breeder",
//                       "selected": false
//                   },
//                   {
//                       "label": "Pet shop",
//                       "value": "pet_shop",
//                       "selected": false
//                   },
//                   {
//                       "label": "Private",
//                       "value": "private",
//                       "selected": false
//                   }
//               ],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "select",
//           "props": {
//               "required": true,
//               "label": "Health",
//               "placeholder": "Select",
//               "name": "health",
//               "multiple": false,
//               "values": [
//                   {
//                       "label": "Healthy",
//                       "value": "healthy",
//                       "selected": false
//                   },
//                   {
//                       "label": "Sick",
//                       "value": "sick",
//                       "selected": false
//                   },
//                   {
//                       "label": "Injured",
//                       "value": "injured",
//                       "selected": false
//                   }
//               ],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "select",
//           "props": {
//               "required": true,
//               "label": "Color",
//               "placeholder": "Select",
//               "name": "color",
//               "multiple": false,
//               "values": [
//                   {
//                       "label": "White",
//                       "value": "white",
//                       "selected": false
//                   },
//                   {
//                       "label": "Black",
//                       "value": "black",
//                       "selected": false
//                   },
//                   {
//                       "label": "Brown",
//                       "value": "brown",
//                       "selected": false
//                   }
//               ],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "date",
//           "props": {
//               "required": true,
//               "label": "Availability",
//               "placeholder": "Select",
//               "name": "availability",
//               "multiple": false,
//               "values": [],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "radiogroup",
//           "props": {
//               "required": true,
//               "label": "Gender",
//               "placeholder": "",
//               "name": "gender",
//               "multiple": false,
//               "values": [
//                   {
//                       "label": "Male",
//                       "value": "male",
//                       "selected": true
//                   },
//                   {
//                       "label": "Female",
//                       "value": "female",
//                       "selected": false
//                   }
//               ],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "checkboxgroup",
//           "props": {
//               "required": true,
//               "label": "Officially Registered Breeding",
//               "placeholder": "",
//               "name": "officially_registered_breeding",
//               "multiple": false,
//               "values": [],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "signature",
//           "props": {
//               "required": true,
//               "label": "Signature",
//               "placeholder": "",
//               "name": "signature",
//               "multiple": false,
//               "values": [],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       },
//       {
//           "type": "textarea",
//           "props": {
//               "required": true,
//               "label": "Descriptive Paragraph",
//               "placeholder": "Type Here",
//               "name": "descriptive_paragraph",
//               "multiple": false,
//               "values": [],
//               "type": "defined",
//               "customValue": 0,
//               "renderConditionally": false,
//               "condition": {
//                   "fieldName": "",
//                   "fieldValue": ""
//               },
//               "pattern": "",
//               "maxLength": "",
//               "minLength": "",
//               "minValue": "",
//               "maxValue": "",
//               "className": "form-control"
//           }
//       }
//   ],
//   "additional": [],
//   "other": [],
//   "selectedCategories": [

//   ],
//   "deletedOn": 0,
//   "status": true,
//   "id": 12
// }
