import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const FormField = ({ id, label, placeholder, note, formik, disabled }) => (
  <FormControl variant="standard" fullWidth>
    <InputLabel htmlFor={id} sx={inputLabelStyle}>
      {label} {note && <small>{note}</small>}
    </InputLabel>
    <Input
      id={id}
      placeholder={placeholder}
      name={id}
      value={formik.values[id] || ""}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      disabled={disabled}
    />
    {formik.touched[id] && formik.errors[id] && (
      <FormHelperText sx={{ color: "red" }}>{formik.errors[id]}</FormHelperText>
    )}
  </FormControl>
);

const inputLabelStyle = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "13px",
  fontWeight: 500,
};

const sectionHeaderStyle = {
  color: "#26252C",
  fontFamily: "Work Sans",
  fontSize: "16px",
  fontWeight: 500,
  marginTop: "32px",
};

const ProfilePage = ({ formik }) => {
  return (
    <>
      <>
        {/* Personal Information */}
        <div style={sectionHeaderStyle}>
          <p>Personal Information</p>
          <Divider
            className="horizontalLine"
            sx={{ marginLeft: "60px", marginTop: "-2px" }}
          />
        </div>
        <Grid container spacing={3}>
          {[
            {
              id: "nickname",
              label: "Nickname",
              placeholder: "Enter Here",
            },
            {
              id: "first_name",
              label: "First Name",
              placeholder: "Enter first name",
            },
            {
              id: "last_name",
              label: "Last Name",
              placeholder: "Enter Here",
            },
            {
              id: "dob",
              label: "Date of Birth",
              placeholder: "Enter Here",
              note: "(Only visible for you)",
            },
            {
              id: "gender",
              label: "Gender",
              placeholder: "Select",
              options: [
                { value: "", label: "Select Gender" },
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
                { value: "other", label: "Other" },
              ],
            },
          ].map(({ id, label, placeholder, note, options }) => (
            <Grid item xs={12} sm={3} key={id}>
              {id === "gender" ? (
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      color: "black",
                      fontSize: "13px",
                      fontWeight: 500,
                      marginBottom: "2px",
                      transform: "translateY(-3px)",
                    }}
                  >
                    {label}
                  </InputLabel>
                  <Select
                    id={id}
                    name={id}
                    value={formik.values[id]}
                    onChange={formik.handleChange}
                    sx={{
                      borderBottom: "1px solid grey",
                      borderRadius: 0,
                      padding: "10px 0 4px 0",
                      "& .MuiSelect-select": {
                        border: "none",
                        padding: "4px 0",
                        "&:focus": {
                          border: "none",
                          paddingBottom: "2px",
                        },
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormField
                  id={id}
                  label={label}
                  placeholder={placeholder}
                  note={note}
                  formik={formik}
                />
              )}
            </Grid>
          ))}
        </Grid>

        {/* Contact Information */}
        <div
          style={{
            color: "#26252C",
            fontFamily: "Work Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "22px",
            marginTop: "50px",
          }}
        >
          <p> Contact Information </p>
          <Divider
            className="horizontalLine"
            style={{ marginLeft: "58px", marginTop: "-2px" }}
          />
        </div>
        <Grid container spacing={3}>
          {[
            {
              id: "email",
              label: "Email Id",
              placeholder: "Enter Here",
              note: "(Only visible for you)",
            },
            {
              id: "telephone",
              label: "Telephone",
              placeholder: "Enter Here",
              note: "(Only visible for you)",
            },
            {
              id: "phone",
              label: "Phone Number",
              placeholder: "Enter Here",
              note: "(Only visible for you)",
            },
          ].map(({ id, label, placeholder, note }) => (
            <Grid
              item
              xs={12}
              sm={3}
              key={id}
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2,
              }}
            >
              <FormField
                id={id}
                label={label}
                placeholder={placeholder}
                note={note}
                formik={formik}
                disabled={id === "email"}
              />
            </Grid>
          ))}
        </Grid>

        {/* Address Information */}
        <div
          style={{
            color: "#26252C",
            fontFamily: "Work Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "22px",
            marginTop: "50px",
          }}
        >
          <p> Address Information </p>
          <Divider
            className="horizontalLine"
            style={{ marginLeft: "58px", marginTop: "-2px" }}
          />
        </div>
        <Grid container spacing={3}>
          {[
            {
              id: "address",
              label: "Address",
              placeholder: "Enter Here",
              note: "(Only visible for you)",
            },
            {
              id: "zipcode",
              label: "Zip Code",
              placeholder: "Enter Here",
              note: "(Only visible for you)",
            },
            {
              id: "country",
              label: "Country",
              placeholder: "Enter Here",
              note: "(Only visible for you)",
            },
          ].map(({ id, label, placeholder, note }) => (
            <Grid
              item
              xs={12}
              sm={3}
              key={id}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormField
                id={id}
                label={label}
                placeholder={placeholder}
                note={note}
                formik={formik}
              />
            </Grid>
          ))}
        </Grid>
      </>
    </>
  );
};

export default ProfilePage;
