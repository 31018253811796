import { useRef } from "react";
import styled from "@emotion/styled";
import { Box, Grid, ImageListItem, Typography } from "@mui/material";
import uploadIcon from "../../assets/images/MarkePlace/uploadImage.svg";

const UploadBox = styled(Box)(({ theme }) => ({
  border: "1px solid #D8D8DF",
  background: "#FFF",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  cursor: "pointer",
  marginBottom: "16px",
  marginRight: "16px",
}));

const SelectedImagesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  gap: "16px",
  height: "133px",
}));

const ImageItem = styled(ImageListItem)(({ theme }) => ({
  width: "180px",
  height: "100px",
  objectFit: "cover",
}));
const UploadImages = ({ onImagesSelected, selectedImages }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    onImagesSelected(files);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Grid container>
      <UploadBox onClick={handleUploadClick}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="upload-button"
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <img
          src={uploadIcon}
          alt="upload icon"
          style={{
            height: "40px",
            width: "40px",
            paddingTop: "23px",
            paddingBottom: "23px",
          }}
        />
        <Typography
          sx={{
            color: "#000",
            fontFamily: "Work Sans",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "115%",
            paddingLeft: "38px",
            paddingRight: "38px",
          }}
        >
          Upload Images
        </Typography>
      </UploadBox>
      <SelectedImagesContainer>
        {selectedImages.map((image, index) => (
          <ImageItem key={index}>
            <img src={URL.createObjectURL(image)} alt={`selected ${index}`} />
          </ImageItem>
        ))}
      </SelectedImagesContainer>
    </Grid>
  );
};
export default UploadImages;
