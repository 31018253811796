import React from "react";
import { Route, Routes } from "react-router-dom";

// Components
import Home from "../../pages/private/HomePage/Home";
import NotFound from "../../pages/NotFound/NotFound";
import PostNewAdd from "../../pages/private/PostNewAdd/PostNewAdd";
import ProductDetails from "../../pages/private/ProductDetails/ProductDetails";
import SellerProfile from "../../pages/private/SellerProfile/SellerProfile";
import Account from "../../pages/private/Account/Account";
import Profile from "../../pages/private/Profile/Profile";
import Following from "../../pages/private/Following/Following";
import MyAds from "../../pages/private/MyAds/MyAds";
import Favourite from "../../pages/private/Favourite/Favourite";
import Help from "../../pages/private/Help/Help";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import ContactInfo from "../../pages/private/ContactInfo/ContactInfo";
import PreviewPage from "../../pages/private/PreviewPage/PreviewPage";
import CommercialPage from "../../pages/private/Profile/CommercialPage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/account" element={<Account />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/commercial" element={<CommercialPage />} />
      <Route path="/following" element={<Following />} />
      <Route path="/my-ads" element={<MyAds />} />
      <Route path="/favourite" element={<Favourite />} />
      <Route path="/help" element={<Help />} />
      <Route path="/product-details" element={<ProductDetails />} />
      <Route path="/seller-profile" element={<SellerProfile />} />
      <Route path="/post" element={<PostNewAdd />} />
      <Route path="/category/:id" element={<CategoryCard />} />
      <Route path="/post/contact-information" element={<ContactInfo />} />
      <Route path="/post/preview" element={<PreviewPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default PrivateRoute;
